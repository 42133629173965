import axios from "axios";
import {jwtDecode} from "jwt-decode";
import { PassLogoutProvider } from "../components/AuthContext";
import { UtilProvider } from "../components/UtilContext";
/* common variables */
//let baseURL = `http://127.0.0.1:8000`;
let baseURL = `https://imaschellinggroup.eu`;
let tokensData = JSON.parse(localStorage.getItem("tokens"));
var authHeader;
let refreshTokenPromise=0;
/*if(tokensData?.access_token){
    authHeader = `Bearer ${JSON.parse(localStorage.getItem("tokens")).access_token}`;
}*/

const axiosToken = axios.create({
    //baseURL: 'http://127.0.0.1:8000'
    baseURL: 'https://imaschellinggroup.eu'
});


/* settings for GET requests to the server */ 
export const refreshToken = async() => {
    refreshTokenPromise=1;
    const authData = JSON.parse(localStorage.getItem("tokens"));
    try {
        let apiResponse = await axiosToken.get("/api/auth/refreshToken",{headers: {
            'Authorization': `Bearer ${authData.access_token}`
        }});
        if(apiResponse.status >= 400){
            authHeader=null;
            PassLogoutProvider.logout();
            UtilProvider.setVisible(false);
            await new Promise(resolve => setTimeout(refreshTokenPromise=0, 50));
        }
        else{
            localStorage.setItem("tokens", JSON.stringify(apiResponse.data));
            authHeader=`Bearer ${apiResponse.data.access_token}`;
            //console.log("AUTH HEADER",authHeader);
            if(PassLogoutProvider.user==null){
                let decoded_token = jwtDecode(apiResponse.data.access_token);
                PassLogoutProvider.setUser(decoded_token["user"]);
            }
            await new Promise(resolve => setTimeout(refreshTokenPromise=0, 50));
        }
    } 
    catch (error) {
        authHeader=null;
        PassLogoutProvider.logout();
        UtilProvider.setVisible(false);
        await new Promise(resolve => setTimeout(refreshTokenPromise=0, 50));
    }
}

export const checkToken = async() => {
    let decoded_token = jwtDecode(JSON.parse(localStorage.getItem("tokens")).access_token);
    if(decoded_token.exp * 1000 < Date.now() && !refreshTokenPromise){
        refreshToken();
    }
    while(refreshTokenPromise){
        await new Promise(resolve => setTimeout(resolve, 50));
    }
}


/* settings for GET requests to the server */ 
export const getRequest = async(component) => {
    await checkToken();
    if(!authHeader)authHeader = `Bearer ${JSON.parse(localStorage.getItem("tokens")).access_token}`;
    const response = await fetch(baseURL+component, { 
        method: "GET",  
        headers: {
            'Authorization': authHeader
        }
    });
    if(component="/api/auth/logout"){
        authHeader=null;
    }
    /* returning a response from the server  */
    return await response.json();
}


/* settings for PUT requests to the server */ 
export const putRequest = async(component,values,multipart=false) => {
    await checkToken();
    if(!authHeader)authHeader = `Bearer ${JSON.parse(localStorage.getItem("tokens")).access_token}`;
    var putHeaders;
    if(multipart){
        putHeaders = {
            'Authorization': authHeader
        };
    }
    else{
        putHeaders ={
            'Content-Type':'application/json',
            'Authorization': authHeader
        }
    }
    const response = await fetch(baseURL+component, { 
        method: "PUT", 
        body: values, 
        headers: putHeaders
    });
    /* returning a response from the server  */
    return await response.json();
}

/* settings for POST requests to the server */ 
export const postRequest = async(component,values,multipart=false) => {
    await checkToken();
    if(!authHeader)authHeader = `Bearer ${JSON.parse(localStorage.getItem("tokens")).access_token}`;
    var postHeaders;
    if(multipart){
        postHeaders = {
            'Authorization': authHeader
        };
    }
    else{
        postHeaders ={
            'Content-Type':'application/json',
            'Authorization': authHeader
        }
    }

    const response = await fetch(baseURL+component, { 
        method: "POST",  
        headers: postHeaders,
        body:values 
    });
    
    return await response.json();
}

/* settings for DELETE requests to the server */
export const deleteRequest = async(component) => {
    await checkToken();
    if(!authHeader)authHeader = `Bearer ${JSON.parse(localStorage.getItem("tokens")).access_token}`;
    const response = await fetch(baseURL+component, { 
        method: "DELETE",  
        headers: {
            'Authorization': authHeader
        }
    });

    return await response.json();
}

/* function for detecting communication with the server */
export async function checkConnection(){
    if(!authHeader)authHeader = `Bearer ${JSON.parse(localStorage.getItem("tokens")).access_token}`;
    const response = await fetch(baseURL+"/check", { 
        method: "GET",  
        headers: {
            'Authorization': authHeader
        }});
    return await response.json();
}

/* settings for POST requests to the server */ 
export const authPostRequest = async(component,values) => {
    var postHeaders;
    postHeaders ={
        'Content-Type':'application/json',
    }

    const response = await fetch(baseURL+component, { 
        method: "POST",
        headers: postHeaders,
        body:values 
    });
    /* returning a response from the server  */
    return await response.json();
}

