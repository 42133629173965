import React,{ useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import Navbar from "./Navbar";

/* checking whether the user is logged in and his access to the application */
const ProtectedRoute = ({ children, accessBy }) => {
  const { user } = useContext(AuthContext);

  /* unlogged user */
  if (accessBy === "non-authenticated") {
    if (!user) {
      return children;
    }
    if (user) {
      return <Navigate to="/"></Navigate>;
    }
  } 
  /* only logged user */
  else if (accessBy === "authenticated") {
    if (user) {
      return <><Navbar/> {children}</>;
    }
    /* otherwise he must log in */
    if (!user) {
      return <Navigate to="/login"></Navigate>;
    }
  }
};
export default ProtectedRoute;
