import React,{useContext,useEffect, useState,useRef} from 'react';
import {Link} from 'react-router-dom';
import { useFormik } from 'formik'; 
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { SaveAndCloseIcon,SaveIcon,AddIcon, EditIcon, CopyIcon, DeleteIcon,SearchIcon, SearchIconPrio, ProblemIcon,PaperClipIcon, ActiveCopyIcon,LoadPrioIcon, PrioBack,RefreshIcon, PrioSort, CalendarSort} from "../svg/svgIcons";
//import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import { CiBarcode } from "react-icons/ci";
import AuthContext from '../../components/AuthContext';
import { Dropdown } from 'primereact/dropdown';
import Quagga from 'quagga';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import UtilContext from '../../components/UtilContext';
import { MdSearch } from "react-icons/md";
import { MdSearchOff } from "react-icons/md";
import { Select } from "antd";
window.DateTime = DateTime

export default function PriorityList(query_props){

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,visible,setDialogText,setVisible}= useContext(UtilContext);

    const [saveAndClose,setSaveAndClose] = useState(false);

    const submitRef = useRef(null);

    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [insertIcon,setInsertIcon] = useState(!user.permissions.includes("edit priorities"));
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [favoriteIcon,setFavoriteIcon] = useState(false);
    const [copyIcon,setCopyIcon] = useState(true);
    const [searchIconColor,setSearchIconColor] = useState("");

    const [visibleSearch,setVisibleSearch] = useState(false);
    const [searchValue,setSearchValue] = useState('');
    const [scrollValue,setScrollValue] = useState(1);

    const [queryProps,setQueryProps] = useState(query_props);

    const [selectedDepartment,setSelectedDepartment] = useState("");
    const [selectedPriorityState,setSelectedPriorityState] = useState("Otvorené");

    const [visibleAddProblem,setVisibleAddProblem] = useState(false);
    const [visibleUpdateProblem,setVisibleUpdateProblem] = useState(false);

    const [priorityProblem, setPriorityProblem] = useState(null);
    const [priorityForm, setPriorityForm] = useState(null);
    const [formikProblemState, setFormikProblemState] = useState(false);
    const [selectedPriority,setSelectedPriority] = useState(null);

    const [sortPriorities,setSortPriorities] = useState("own_priority");


    const [priorities,setPriorities] = useState(state?.priorities ? state.priorities :  [])

    const [employeeDepartment, setEmployeeDepartment] = useState();
    const [employee, setEmployee] = useState([]);

    const [contractorDepartment,setContractorDepartment] = useState("");
    const [solverDepartment, setSolverDepartment] = useState();
    const [solver, setSolver] = useState([]);

    const [showTable, setShowTable] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showBarScanner, setShowBarScanner] = useState(false);
    const [formType, setFormType] = useState("add");

    const [scanValue,setScanValue] = useState("");
    const [scanTitle,setScanTitle] = useState("");

    const ref = useRef(null);
    const ref2 = useRef(null);
    const priorityFromDepartmentRef = useRef(null);
    const prioritiesRef = useRef(null);
    const problemShowHideRef = useRef(null);
    const componentRef = useRef(null);


    const {
      transcript,
      listening
    } = useSpeechRecognition();
    const startListening = () => SpeechRecognition.startListening({ language: 'sk-SK'});

    async function waitForTables(){
      while(ref.current==null || ref2.current==null){
          console.log(ref.current,ref2.current);
          await new Promise(resolve => setTimeout(resolve, 200));
      }
    }

    const firstUpdate = useRef(true);
    useEffect(() => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
      showPriorities(null);
  },[selectedDepartment,selectedPriorityState]);

    function customFilter(data, filterParams){
      //data - the data for the row being filtered
      //filterParams - params object passed to the filter
      if(filterParams.department_id!=""){
        let problems = data?.priority_problems;
        if(filterParams.state=="Ručne pridané"){
          for(let i = 0;i<problems.length;i++){
            if(problems[i].offender_department_id == filterParams.department_id){
              if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.assignment_type!="automatic")return true;
              else return false;
            }
          }
          return false;
        }
        else if(filterParams.state=="Duplikáty"){
          for(let i = 0;i<problems.length;i++){
            if(problems[i].offender_department_id == filterParams.department_id){
              if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.duplicate==1)return true;
              else return false;
            }
          }
          return false;
        }
        else if(filterParams.state=="Uzavreté"){
          return false;
        }
        else{
          for(let i = 0;i<problems.length;i++){
            if(problems[i].offender_department_id == filterParams.department_id){
              if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return true;
              else return false;
            }
          }
          return false;
        }
      }
      else{
        let problems = data?.priority_problems;
        if(filterParams.state=="Ručne pridané"){
          for(let i = 0;i<problems.length;i++){
            if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.assignment_type!="automatic")return true;
          }
          return false;
        }
        else if(filterParams.state=="Duplikáty"){
          for(let i = 0;i<problems.length;i++){
            if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.duplicate==1)return true;
          }
          return false;
        }
        else if(filterParams.state=="Uzavreté"){
          return false;
        }
        else{
          for(let i = 0;i<problems.length;i++){
            if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return true;
          }
          return false;
        }
      }
    }

    function openedFilter(data, filterParams){
      if(filterParams.department_id!=""){
        return data.priority_department_id == filterParams.department_id && data.validity==1;
      }
      else{
        let problems = data?.priority_problems;
        for(let i = 0;i<problems.length;i++){
          if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return false;
        }
        return data.validity==1;
      }
    }

    function closedFilter(data, filterParams){
      if(filterParams.department_id!=""){
        return data.priority_department_id == filterParams.department_id && data.validity==0;
      }
      else{
        return data.validity==0;
      }
    }

    function manuallyFilter(data, filterParams){
      if(filterParams.department_id!=""){
        return data.priority_department_id == filterParams.department_id && data.validity==1 && data.assignment_type!="automatic";
      }
      else{
        let problems = data?.priority_problems;
        for(let i = 0;i<problems.length;i++){
          if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return false;
        }
        return data.validity==1 && data.assignment_type!="automatic";
      }
    }

    function duplicateFilter(data, filterParams){
      if(filterParams.department_id!=""){
        return data.priority_department_id == filterParams.department_id && data.validity==1 && data.duplicate==1;
      }
      else{
        let problems = data?.priority_problems;
        for(let i = 0;i<problems.length;i++){
          if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return false;
        }
        return data.validity==1 && data.duplicate==1;
      }
    }

    function problemFilter(data, filterParams){
      let problems = data?.priority_problems;
      if(filterParams.department_id!=""){
        for(let i = 0;i<problems.length;i++){
          if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.priority_department_id == filterParams.department_id && data.validity==1)return true;
        }
        return false;
      }
      else{
        for(let i = 0;i<problems.length;i++){
          if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.validity==1)return true;
        }
        return false;
      }
    }

    async function waitForTables(){
      while(!ref.current || !ref2.current){
          console.log(ref.current,ref2.current);
          await new Promise(resolve => setTimeout(resolve, 20));
      }
  }

  async function showPriorities(data){
    //JEDNO ODDELENIE
      waitForTables().then(() => {
      prioritiesRef.current.style.display = "block";
      ref.current?.clearFilter();
      ref2.current?.clearFilter();
      console.log(ref.current.getFilters(),ref2.current.getFilters());
      //ref.current?.rowManager?.table?.replaceData(data!=null ? data : state.priorities).then(function(rows){
          switch(selectedPriorityState){
              case "Otvorené": {
                  ref.current?.setFilter(openedFilter, {department_id:selectedDepartment});
                  break;
              }
              case "Uzavreté": {
                  ref.current?.setFilter(closedFilter, {department_id:selectedDepartment});
                  break;
              }
              case "Problémové":{
                  ref.current?.setFilter(problemFilter, {department_id:selectedDepartment});
                  if(selectedDepartment=="")prioritiesRef.current.style.display = "none";
                  break;
              }
              case "Ručne pridané":{
                ref.current?.setFilter(manuallyFilter, {department_id:selectedDepartment});
                break;
              }
              case "Duplikáty": {
                ref.current?.setFilter(duplicateFilter, {department_id:selectedDepartment});
                break;
              }
              default: {
                  ref.current?.setFilter(openedFilter, {department_id:selectedDepartment});
                  break;
              }
          }
          //ref.current?.redraw("true");
      //});
      //ref2.current?.replaceData(data!=null ? data : state.priorities).then(function(rows){
          ref2.current?.setFilter(customFilter, {department_id:selectedDepartment,state:selectedPriorityState});
          //ref2.current?.redraw("true");
      //});
      sortByOwnPriority();
      setSearchIconColor("");
      });
      if(data!=null)dispatch({type:'load_priorities',newPriorities:data});
  }
  
  function filterEmployees(departmentId){
    setEmployeeDepartment(departmentId);
    console.log(state.priority_departments_employees);
    if(visibleAddProblem)formik.setFieldValue("contractor_id","");
    setEmployee(state.priority_departments_employees?.filter((item => item.id == departmentId))[0]?.employees
    .filter((employee) => employee.date_of_departure == null || (employee.date_of_departure && DateTime.fromISO(employee.date_of_departure) < DateTime.now().toISO())));
  }
  
  function filterSolvers(departmentId){
    setSolverDepartment(departmentId);
    console.log(state.priority_departments_employees);
    formikUpdate.setFieldValue("solver_id","");
    setSolver(state.priority_departments_employees?.filter((item => item.id == departmentId))[0]?.employees
    .filter((solver) => solver.date_of_departure == null || (solver.date_of_departure && DateTime.fromISO(solver.date_of_departure) < DateTime.now().toISO())));
  }
  
  
  function matchAny(data, filterParams) {
    //data - the data for the row being filtered
    //filterParams - params object passed to the filter
    //RegExp - modifier "i" - case insensitve

    var match = false;
    const regex = RegExp(filterParams.value, 'i');

    for (var key in data) {
        if (regex.test(data[key]) === true) {
            match = true;
        }
    }
    return match;
  }

  function searchTable(){
    if (searchValue !== "") {
      setSearchIconColor("active");
      ref.current.addFilter(matchAny, { value: searchValue});
      ref2.current.addFilter(matchAny, { value: searchValue});
    }
    setVisibleSearch(false);
  }

  const accept = () => {
    setDialogText("Vymazávam")
    setVisible(true);
    let delete_priority = ref.current.getSelectedData().length ? ref.current.getSelectedData()[0] : ref2.current.getSelectedData()[0];
    deleteRequest(`/api/priorities/${delete_priority.id}`)
    .then((response) => {
      if(response.status == 200){
        let rows = ref.current.searchRows("id", "=", delete_priority.id);
          rows[0].update(response.data);
          rows[0].deselect();
          rows[0].reformat();
          rows = ref2.current.searchRows("id", "=", delete_priority.id);
          rows[0]?.update(response.data);
          rows[0]?.deselect();
          rows[0]?.reformat();
        ref.current.refreshFilter();
        ref2.current.refreshFilter();
        setVisible(false);
        toast.current.show({ severity: 'success', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
      }
      else{
        setVisible(false);
        toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
      }
    }).catch((reason) => {
        setVisible(false);
        alert(reason);
        toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
    })
}

const acceptProblemDelete = () => {
  setDialogText("Vymazávam")
  setVisible(true);
  deleteRequest(`/api/priority_problems/${formikUpdate.values.id}`)
  .then((response) => {
    if(response.status == 200){
      let rows = ref.current.searchRows("id", "=", formikUpdate.values.priority_id);
      rows[0]?.update(response.data);
      rows[0]?.reformat();
      rows = ref2.current.searchRows("id", "=", formikUpdate.values.priority_id);
      rows[0]?.update(response.data);
      rows[0]?.reformat();
      ref.current.refreshFilter();
      ref2.current.refreshFilter();
      setVisible(false);
      hideUpdateProblem();
      formikUpdate.resetForm();
      toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
    }
  }).catch((reason) => {
      setVisible(false);
      hideUpdateProblem();
      formikUpdate.resetForm();
      alert(reason);
      toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
  })
}

const confirmProblemDelete = () => {
  confirmDialog({
    message: 'Prajete si vymazať tento problém?',
    header: 'Potvrdenie vymazania',
    icon: 'pi pi-info-circle',
    rejectClassName: 'p-button-danger',
    acceptLabel: 'Nie',
    rejectLabel: 'Áno',
    defaultFocus: 'accept',
    reject: acceptProblemDelete,
    accept: reject
  });
};

const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
}

    function addNewPrio(){
      setShowTable(false);
      setShowBarScanner(false);
      setShowForm(true);
      setFormType("add");
    }
    function copyPrio(){
      setShowTable(false);
      setShowBarScanner(false);
      setShowForm(true);
      setFormType("add");
      formikPrio.setValues(ref.current.getSelectedData()[0]);
    }
    function editPrio(){
      setShowTable(false);
      setShowBarScanner(false);
      setShowForm(true);
      setFormType("edit");
      formikPrio.setValues(ref.current.getSelectedData().length ? ref.current.getSelectedData()[0] : ref2.current.getSelectedData()[0]);
    }
    function barScanner(scanType,title){
      setShowBarScanner(true);
      setShowTable(false);
      setShowForm(false);
      setScanValue(scanType);
      setScanTitle(title);
    }

    function barScannerSearch(scanType,title){
      setVisibleSearch(false);
      setShowTable(false);
      setScanValue(scanType);
      setScanTitle(title);
      setShowBarScanner(true);
    }

    const stopScanner = () => {
      Quagga.offProcessed();
      Quagga.offDetected();
      Quagga.stop();
  };

      /* start scan barcode */
      

    const confirm = () => {
        confirmDialog({
          message: 'Prajete si vymazať tento záznam/y?',
          header: 'Potvrdenie vymazania',
          icon: 'pi pi-info-circle',
          rejectClassName: 'p-button-danger',
          acceptLabel: 'Nie',
          rejectLabel: 'Áno',
          defaultFocus: 'accept',
          reject: accept,
          accept: reject
        });
    };

  const rowClick = (e, row) => {
    //console.log(e, row);
 };

 const onSelectRow = (data, rows) => {
  setPriorityForm(data);
  setEditIcon(true);
  setCopyIcon(true);
  setDeleteIcon(true);
  if(ref.current.getSelectedData().length + ref2.current.getSelectedData().length === 1){
      //TODO dorobit aj contractorov
      if(ref.current.getSelectedData().length){
        if(ref.current.getSelectedData()[0].assignment_type!=="automatic"){
          if(user.permissions.includes("edit priorities"))setEditIcon(false);
          if(user.permissions.includes("delete priorities") && ref.current.getSelectedData()[0].validity===1)setDeleteIcon(false);
        }
        setSelectedPriority(ref.current.getSelectedData()[0]);
      }
      else if(ref2.current.getSelectedData().length){
        if(ref2.current.getSelectedData()[0].assignment_type!=="automatic"){
          if(user.permissions.includes("edit priorities"))setEditIcon(false);
          if(user.permissions.includes("delete priorities") && ref2.current.getSelectedData()[0].validity===1)setDeleteIcon(false);
        }
        setSelectedPriority(ref2.current.getSelectedData()[0]);
      }
      if(user.permissions.includes("edit priorities"))setCopyIcon(false);
  }
};

function rowMovePriorityEvent(row){
  setDialogText("Ukladám");
  setVisible(true);
  let rows = ref.current.getData("active");
  //rows = rows.filter((get_row) => get_row.priority_department_id == row.getData().priority_department_id);
  rows.sort((a,b) => ref.current.searchRows("id", "=", a.id)[0]?.getPosition() > ref.current.searchRows("id", "=", b.id)[0]?.getPosition() ? 1:-1);
  let formData = new FormData();
  let i = 1;
  rows.reverse().forEach(my_row => {
    let row_data = {id: my_row.id,son: my_row.sales_order_number,own_priority: i};
    my_row.own_priority = i;
    i = i+1;
    formData.append("priorities[]",JSON.stringify(row_data))
  })
  postRequest('/api/priorities/updatePriority', formData,true)
  .then((response) => {
      if(response.status == 200){
        for(let i = 0;i<rows.length;i++){
          let db_rows = ref.current.searchRows("id", "=", rows[i].id);
          db_rows[0]?.update(rows[i]);
          db_rows[0]?.reformat();
          db_rows = ref2.current.searchRows("id", "=", rows[i].id);
          db_rows[0]?.update(rows[i]);
          db_rows[0]?.reformat();
        }
        sortByOwnPriority();
        setVisible(false);
        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Priority boli nastavené!" });
      }
  }).catch((reason) => {
      setVisible(false);
      alert("Daný úkon sa nepodaril!");
  })
};

function rowMoveProblemEvent(row){
  setDialogText("Ukladám");
  setVisible(true);
  let rows = ref2.current.getData("active");
  //rows = rows.filter((get_row) => get_row.priority_department_id == row.getData().priority_department_id);
  rows.sort((a,b) => ref2.current.searchRows("id", "=", a.id)[0]?.getPosition() > ref2.current.searchRows("id", "=", b.id)[0]?.getPosition() ? 1:-1);
  let formData = new FormData();
  let i = 1;
  rows.reverse().forEach(my_row => {
    let row_data = {id: my_row.id,son: my_row.sales_order_number,own_priority: i};
    my_row.own_priority = i;
    i = i+1;
    formData.append("priorities[]",JSON.stringify(row_data))
  })
  postRequest('/api/priorities/updatePriority', formData,true)
  .then((response) => {
    if(response.status == 200){
      for(let i = 0;i<rows.length;i++){
        let db_rows = ref.current.searchRows("id", "=", rows[i].id);
        db_rows[0]?.update(rows[i]);
        db_rows[0]?.reformat();
        db_rows = ref2.current.searchRows("id", "=", rows[i].id);
        db_rows[0]?.update(rows[i]);
        db_rows[0]?.reformat();
      }
      sortByOwnPriority();
      setVisible(false);
      toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Priority boli nastavené!" });
    }
  }).catch((reason) => {
      setVisible(false);
      alert("Daný úkon sa nepodaril!");
  })
};

    async function copyTextToClipboard(text) {
      return await navigator.clipboard.writeText(text);
    }

    const handleCopyClick = (copyText,setIsCopied) => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(copyText)
        .then(() => {
          toast.current.show({ severity: 'info', summary: 'Schránka', life: 3000, detail: "Skopírované do schránky!" });
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function setEmployeeToPriority(set_employee_id,priority,row){
      setDialogText("Ukladám");
      setVisible(true);
      let formData = new FormData();
      formData.append("employee_id", set_employee_id);
      formData.append("chosen_by_manager", user.permissions.includes("edit priorities") ? 1 : 0);
      postRequest(`/api/priorities/updateEmployee/${priority.id}`, formData,true)
      .then((response) => {
          if(response.status == 200){
            row.update({employee_id:set_employee_id});
            row.reformat();
            setVisible(false);
            toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Zamestnanec bol priradený!" });
          }
      }).catch((reason) => {
        console.log(reason);
        setVisible(false);
        alert("Daný úkon sa nepodaril!");
      });
    }

    function DepartmentFormatter(props) {
      const rowData = props.cell._cell.row.data;
      let str = rowData.description_1?.substring(0, 10);;
      return (
        <div className={rowData.assignment_type!=="automatic" ? 'manualPriority flex flex-col' :'flex flex-col'}>
        <div className='flex flex-row'>{rowData.priority_department?.name}</div>
            {(rowData.production_order_number!="" && rowData.production_order_number!=null) ?
            <div  className='flex flex-row'>
              {rowData.production_order_number}
            </div>:<br/>}
            {str!="" && str!=null &&
            <div className='flex flex-row'>
              {str}
            </div>}
            {rowData.delivery_date!="" && rowData.delivery_date!=null &&
            <div className='flex flex-row'>
              {DateTime.fromISO(rowData.delivery_date).toFormat("dd.MM.yyyy")}
          </div>}
          </div>
      )
    }

    function setEmployeePermission(rowData){
      if(user.permissions.includes("edit priorities")){
        return false;
      }
      else{
        if((rowData.state === "Pracuje" || rowData.state === "Hotovo" || rowData.state === "Problém" || rowData.state === "Riešim problém")){//(rowData.employee_id!=null && rowData.employee_id!="")
          return true;
        }
        if(!user.permissions.includes("add priorities")){
          return true;
        }
        if(rowData.chosen_by_manager==1){
          return true;
        }
        if(user.priority_departments?.length > 0){
          let user_departments = user.priority_departments.map(department => department.id);
          if(!user_departments.includes(rowData.priority_department_id)){
            return true;
          }
        }
        else{
          let result = state.priority_departments_employees.filter(department => department.employees.some(employee => employee.id == user.employee.id));
          if(result.length==0 || (result.length > 0 && rowData.priority_department_id !=result[0].id)){
            return true
          }
        }
        return false;
      }    
    }

    function ArticleDateFormatter(props) {
      const rowData = props.cell.getRow().getData();
      const row = props.cell.getRow();
      let str = rowData.sales_order_number?.substring(0, 12);
      let str2 = rowData.description_2?.substring(0, 10);
      if(rowData.sales_order_number?.length>10 && str2 != undefined)str2 = str2+"..";
        const [isActive, setIsActive] = useState(false);
        //D J
        return (
      <div className={rowData.duplicate ? 'duplicate flex flex-col w-[115px]' : 'flex flex-col w-[115px]'}>
          {(rowData.article_number!="" && rowData.article_number!=null) ?
            <div className= 'flex flex-row text-blue-600'>
              {(rowData.drawing_url != "" && rowData.drawing_url != null && rowData.drawing_url.charAt(1)==":") ? <div className="flex flex-row text-green-600" onClick={() => handleCopyClick(rowData.drawing_url,setIsActive)}>
                {rowData.article_number}
              </div>:<a href={rowData.drawing_url} target='_blank' className='underline italic'>
                {rowData.article_number}
            </a>}
          </div>:<br/>}
          {str!="" && str!=null &&
            <div  className='flex flex-row'>
              {str}
            </div>}
            {str2!="" && str2!=null &&
            <div className="flex flex-row">
              {str2}
            </div>}
          <div className="flex flex-row">
          <Select style={{ width: 110, height: 32, padding:0 }} size='small' showSearch={true} optionFilterProp="children" value={rowData.employee_id || ""} onChange={(value) => setEmployeeToPriority(value,rowData,row)} disabled={setEmployeePermission(rowData) ? true : false}
            >
            <Select.Option value="">Vyber zamestnanca</Select.Option>
              {state.priority_departments_employees?.filter((item => item.abbreviation == rowData.priority_department?.abbreviation))[0]?.employees
              .filter((employee) => employee.date_of_departure == null || (employee.date_of_departure && DateTime.fromISO(employee.date_of_departure) < DateTime.now().toISO()))
              .map((employee) => (
                <Select.Option key={employee.id} value={employee.id}>{employee.name.replace("Ing.","").replace("Mgr.","")}</Select.Option>
                ))}
            </Select>
          </div>
      </div>
        ); 
    }

    function setProblemPermission(rowData,problem){
      if(user.permissions.includes("edit priorities")){
        return true;
      }
      if(rowData.state!="Hotovo"){
        if(user.permissions.includes("add priorities")){
          if(user.priority_departments?.length > 0){
            let user_departments = user.priority_departments.map(department => department.id);
            if(user_departments.includes(problem.offender_department_id)){
              return true;
            }
          }
          else{
            let result = state.priority_departments_employees.filter(department => department.employees.some(employee => employee.id == user.employee.id));
            if(result.length > 0 && (problem.offender_department_id==result[0].id)){//rowData.priority_department_id == result[0].id || 
              return true;
            } 
          }
        }
      }
      return false;
    }

    function setStatePermission(rowData){
      if(user.permissions.includes("edit priorities")){
        return true;
      }
      if(rowData.state!="Hotovo"){
        if(user.permissions.includes("add priorities")){
          if(user.priority_departments?.length > 0){
            let user_departments = user.priority_departments.map(department => department.id);
            if(user_departments.includes(rowData.priority_department_id) && rowData.employee_id!=null && rowData.employee_id!=""){
              return true;
            }
          }
          else{
            let result = state.priority_departments_employees.filter(department => department.employees.some(employee => employee.id == user.employee.id));
            if(result.length > 0 && (rowData.priority_department_id == result[0].id && rowData.employee_id!=null && rowData.employee_id!="")){
              return true;
            }
          }
        }
      }
      return false;
    }

    function ProblemFormatter(props) {
        const rowData = props.cell._cell.row.data;
        return <div className='h-[115px] overflow-y-auto'>
          <div className='flex flex-col flex-wrap'>
        {(rowData.total_amount!="" && rowData.total_amount!=null) ?
            <div className='flex flex-row'>
            Ks: {rowData.total_amount}
          </div>:<br/>}
          <div className='flex flex-row'>
        {rowData.priority_problems.map((problem) => {
          let color = states.filter(state => state.name == problem.state)[0]?.color;
          return <button type='button' onClick={setProblemPermission(rowData,problem) ? () => editPriorityProblem(rowData,problem) : null}><ProblemIcon color={color}/></button>;
        })}
        {(rowData.state=="Pracuje" || rowData.state=="Hotovo") ?
          <button type='button' onClick={rowData.state=="Hotovo" && !user.permissions.includes("edit priorities")  ? null : () => addPriorityProblem(rowData)}><ProblemIcon color={add_states.filter(state => state.name == rowData.state)[0]?.color}/></button>:
          <button type='button' onClick={setStatePermission(rowData) ? () => addPriorityProblem(rowData) : null}><ProblemIcon/></button>
        }
        </div>
        </div><br/>
        <div  className='flex flex-row'>{DateTime.fromISO(rowData.updated_at).toFormat("dd.MM.yyyy")}</div>
        </div>;
    }

  const priority_columns = [
    { rowHandle:true, formatter:"handle", headerSort:false, frozen:true, minWidth:22, width:22,resizable:false},  
    { formatter:"rowSelection",vertAlign:"middle",id:"checkbox", titleFormatter:"rowSelection",headerHozAlign:"center", hozAlign:"center",minWidth:20, width:20,headerSort:false,frozen:true,resizable:false},
    { title: 'ODD/VZ/DT',field: 'custom_department',headerSort:false,widthGrow:1.3,formatter: reactFormatter(<DepartmentFormatter/>),visible:true},
    { title: 'ART/PZ/M', field: 'custom_article_date',headerSort:false,widthGrow:1.5,formatter: reactFormatter(<ArticleDateFormatter/>),resizable:false},
    { title: 'KS/STAV', field: 'problem',headerSort:false,widthGrow:1,formatter: reactFormatter(<ProblemFormatter/>),resizable:false},
    { field: 'state',visible:false},
    { field: 'own_priority',visible:false,sorter:"number"},
    { field: 'validity',visible:false},
    { field: 'priority_department.defined_id',visible:false},
    { field: 'assignment_type',visible:false},
    { field: 'delivery_date',visible:false,sorter: "date", sorterParams: { format: "yyyy-MM-dd",alignEmptyValues:"bottom",}},
  ];

  const problem_columns = [
    { rowHandle:true, formatter:"handle", headerSort:false, frozen:true, minWidth:22,width:22,resizable:false},  
    { formatter:"rowSelection",vertAlign:"middle", titleFormatter:"rowSelection",headerHozAlign:"center", hozAlign:"center",minWidth:20, width:20,headerSort:false,frozen:true,resizable:false},
    { title: 'ODD/VZ/DT',field: 'custom_department',headerSort:false,widthGrow:1.3,formatter: reactFormatter(<DepartmentFormatter/>),visible:true},
    { title: 'ART/PZ/M', field: 'custom_article_date',headerSort:false,widthGrow:1.5,formatter: reactFormatter(<ArticleDateFormatter/>),resizable:false},
    { title: 'KS/STAV', field: 'problem',headerSort:false,widthGrow:1,formatter: reactFormatter(<ProblemFormatter/>),resizable:false},
    { field: 'state',visible:false},
    { field: 'own_priority',visible:false,sorter:"number"},
    { field: 'validity',visible:false},
    { field: 'priority_department.defined_id',visible:false},
    { field: 'assignment_type',visible:false},
    { field: 'delivery_date',visible:false,sorter: "date", sorterParams: { format: "yyyy-MM-dd",alignEmptyValues:"bottom",}},
  ];

  const add_manager_states = user.permissions.includes("edit priorities") ? 
  [
    { name: 'Pracuje', color: 'orange', title: 'Pracuje' },
    { name: 'Hotovo', color: 'green',title:'Hotovo'  },
    { name: 'Problém', color: 'red',title:'Problém'  },
    { name: 'Nová', color: '',title:'Nová'  },
  ] :[
    { name: 'Pracuje', color: 'orange', title: 'Pracuje' },
    { name: 'Hotovo', color: 'green',title:'Hotovo'  },
    { name: 'Problém', color: 'red',title:'Problém'  },
  ];

  const add_states = [
    { name: 'Pracuje', color: 'orange', title: 'Pracuje' },
    { name: 'Hotovo', color: 'green', title: 'Hotovo' },
    { name: 'Problém', color: 'red', title: 'Problém' },
  ];

  const problem_states = [
    { name: 'Problém', color: 'red', title: 'Problém' },
  ];

  const states = [
    { name: 'Problém', color: 'red', title: 'Problém' },
    { name: 'Riešim problém', color: 'blue', title: 'Riešim problém' },
    { name: 'Vyriešený problém', color: 'magenta', title: 'Vyrieš. problém' },
  ];

  const selectedStateTemplate = (option, props) => {
    if (option) {
        return (
          <div className="flex items-center	">
            <ProblemIcon color={option.color}></ProblemIcon>
            <div className='ml-2 text-lg font-semibold'>{option.name}</div>
        </div>
        );
    }

    return <span>{props.placeholder}</span>;
};

  const stateTemplate = (option) => {
    return (
        <div className="flex items-center	">
            <ProblemIcon color={option.color}></ProblemIcon>
            <div className='ml-3 text-xl font-semibold'>{option.name}</div>
        </div>
    );
  };

  function addPriorityProblem(priority) {
    setFormikProblemState((priority.state=="Problém" || priority.state=="Riešim problém") ? true : false );
    setPriorityForm(priority);
    console.log(priority);
    filterEmployees(priority.priority_department_id);
    if(priority.employee_id!=null)formik.setFieldValue("contractor_id",priority.employee_id);
    if(priority.state=="Pracuje" || priority.state=="Hotovo")formik.setFieldValue("state",add_manager_states.filter(state => state.name == priority.state)[0]);
    setVisibleAddProblem(true);
  }

  function editPriorityProblem(priority,priority_problem) {
    for (let value in priority_problem){
      if(value=="state"){
        formikUpdate.setFieldValue(value, states.filter(state => state.name == priority_problem[value])[0]);
      }
      else{
        formikUpdate.setFieldValue(value,priority_problem[value]!="null"?priority_problem[value]:"");
      }
    }
    let result = state.priority_departments_employees.filter(department => department.employees.some(employee => employee.id == priority_problem.contractor_id));
    if(result.length > 0)setContractorDepartment(result[0].id);
    let result2;
    if(priority_problem.solver_id != null){
      result2 = state.priority_departments_employees.filter(department => department.employees.some(employee => employee.id == priority_problem.solver_id));
      if(result2.length > 0)filterSolvers(result2[0].id);
      formikUpdate.setFieldValue("solver_id",priority_problem.solver_id);
    }
    else{
      filterSolvers(priority_problem.offender_department_id);
    }
    setPriorityForm(priority);
    setPriorityProblem(priority_problem);
    //console.log(priority_problem);
    setVisibleUpdateProblem(true);
  }

  function hideAddProblem(){
    setVisibleAddProblem(false);
    setPriorityForm(null);
    formik.resetForm();
  }

  function hideUpdateProblem(){
    setPriorityForm(null);
    setPriorityProblem(null);
    setVisibleUpdateProblem(false);
    formikUpdate.resetForm();
  }

  const validate = values => {
    const errors = {};
    /*if (!values.type) {
        errors.type = '*Povinné';
    }*/
    if(values.state?.name=="Problém"){
      if (values.contractor_id==null || values.contractor_id=="") {
        errors.contractor_id = '*Povinné';
      }
    }
    return errors;
  };


const formik = useFormik({
  initialValues: {
      problem:'',offender_department_id:'',contractor_id:'',state:''
  },
  enableReinitialize:true,
  validate,
  onSubmit: values => {
      let formData = new FormData();
      for(let value in values){
        if(value=="solver_id"){
          formData.append("solver_id", values[value].id);
        }
        else if(value=="state"){
          formData.append(value, values[value].name);
        }
        else{
          formData.append(value,values[value])
        }
      }
      formData.append("priority_id", priorityForm.id);
      postRequest('/api/priority_problems', formData,true)
      .then((response) => {
        console.log(response);
        //TODO SKRATIT TO NA 1 request
        if(response.status == 201){
          //dispatch({type:'load_priorities',newPriorities:response.data});
          //showPriorities(response.data);
          let rows = ref.current.searchRows("id", "=", priorityForm.id);
          rows[0].update(response.data);
          rows[0].reformat();
          rows = ref2.current.searchRows("id", "=", priorityForm.id);
          rows[0].update(response.data);
          rows[0].reformat();
          ref.current.refreshFilter();
          ref2.current.refreshFilter();
          //dispatch({type:'load_priorities',newPriorities:ref.current.getData()});
          hideAddProblem();
          formik.resetForm();
          toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
        }
      }).catch((reason) => {
              alert("Daný úkon sa nepodaril!");
      })
  },
});

const updateValidate = values => {
  const errors = {};
  if (values.solver_id==null || values.solver_id==""){
    errors.solver_id = '*Povinné';
  }
  return errors;
};

    const formikPrio = useFormik({
      initialValues: {
          priority_department_id: '',production_order_number:'',sales_order_number:'',article_number:'',delivery_date:'',
          description_1:'',description_2:'',drawing_name:'',drawing_url:'',total_amount:'', assignment_type:'manually'
      },
      enableReinitialize:true,
      validate,
      onSubmit: values => {
        setDialogText("Ukladám");
        setVisible(true);
        if(formType=="add"){
          if(values.delivery_date=="" && values.article_number==""){
              values["assignment_type"] = "manually_partially";
          }
          else{
              values["assignment_type"] = "manually";
          }
        }
          let formData = new FormData();
          for (let value in values) {
              formData.append(value, values[value]);
          }
          if(formType=="add"){
              postRequest('/api/priorities', formData,true)
              .then((response) => {
                  console.log(response);
                      if(response.status == 201){
                        if(response.data.length>0)formik.setValues(response.element);
                        setFormType("edit");                  
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                        console.log(response.element);
                        showPriorities(response.data);
                        dispatch({type:'load_priorities',newPriorities:response.data});
                        setVisible(false);
                        if(saveAndClose){
                          setShowTable(true);
                          setShowForm(false);
                          setSaveAndClose(false);
                        }
                        /*ref.current.addRow(response.element,false);
                        ref2.current.addRow(response.element,false);
                        ref.current.redraw("true");
                        ref2.current.redraw("true");
                        ref.current.refreshFilter();
                        ref2.current.refreshFilter();*/
                      }

              }).catch((reason) => {
                  if(!navigator.online){
                      alert("Ste offline, dáta budu odoslané po znovupripojení!");
                  }
                  else{
                      alert("Daný úkon sa nepodaril!");
                  }
                  setVisible(false);
              })
          }
          else{
              postRequest(`/api/priorities/update/${formikPrio.values.id}`, formData,true)
              .then((response) => {
                  getRequest("/api/priorities").then((response) => {
                      showPriorities(response.data);
                      dispatch({type:'load_priorities',newPriorities:response.data});
                      formikPrio.setValues(values);
                      toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                      setVisible(false);
                  });
              }).catch((reason) => {
                  if(!navigator.online){
                      alert("Ste offline, dáta budu odoslané po znovupripojení!");
                  }
                  else{
                      alert("Daný úkon sa nepodaril!");
                  }
                  setVisible(false);
              })
          }
      },
    });

    useEffect(() => {
      if(scanValue!=""){
      const _onDetected = res => {
          stopScanner();
          if(scanValue=="production_order_number"){
              formikPrio.setFieldValue("production_order_number",res.codeResult.code);
              setShowTable(false);
              setShowForm(true);
          }
          else if(scanValue=="sales_order_number"){
              formikPrio.setFieldValue("sales_order_number",res.codeResult.code);
              setShowTable(false);
              setShowForm(true);
          }
          else if(scanValue=="search_value"){
            setSearchValue(res.codeResult.code);
            setVisibleSearch(true);
            setShowTable(true);
        }
          setScanValue("");
          setShowBarScanner(false);
         

      };
      /* start camera */
      Quagga.init(
      {
          inputStream: {
          type: "LiveStream",
          constraints: {
              facingMode: "environment", // or user
          },
          },
          locator: {
          patchSize: "medium",
          halfSample: false,
          },
          numOfWorkers: 2,
          frequency: 10,
          decoder: {
          readers: ["code_128_reader"],
          debug: {
              drawBoundingBox: false,
              showFrequency: false,
              drawScanline: false,
              showPattern: false,
          },
          multiple: false,
          },
          locate: true,
      },
      function (err) {
          if (err) {
          return console.log(err);
          }
          Quagga.start();
      }
      );
      Quagga.onDetected(_onDetected);
      }
    }, [scanValue,formikPrio]);

    const formikUpdate = useFormik({
      initialValues: {
          id:'',problem:'',offender_department_id:'',contractor_id:'',solution:'',solver_id:'',state:'Problém',priority_id:'',problem_created_at:'',solution_created_at:''
      },
      enableReinitialize:true,
      validate: updateValidate,
      onSubmit: values => {
      let formData = new FormData();
      for(let value in values){
        if(value=="state"){
          formData.append(value, values[value].name);
        }
        else{
          formData.append(value,values[value])
        }
      }
      formData.append("priority_id", priorityForm.id);
      postRequest(`/api/priority_problems/update/${formikUpdate.values.id}`, formData,true)
      .then((response) => {
        if(response.status == 200){
          let rows = ref.current.searchRows("id", "=", priorityForm.id);
          rows[0].update(response.data);
          rows[0].reformat();
          rows = ref2.current.searchRows("id", "=", priorityForm.id);
          rows[0].update(response.data);
          rows[0].reformat();
          ref.current.refreshFilter();
          ref2.current.refreshFilter();
          hideUpdateProblem();
          formikUpdate.resetForm();
          toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
        }
      }).catch((reason) => {
          if(!navigator.online){
              alert("Ste offline, dáta budu odoslané po znovupripojení!");
          }
          else{
              alert("Daný úkon sa nepodaril!");
          }
      })
    },
  });

  function refreshPriorities(){
    setDialogText("Aktualizujem");
    setVisible(true);
    getRequest("/api/priorities/refresh").then((response) => {
      showPriorities(response.data);
      setVisible(false);
    })
    .catch((reason) => {
      alert(reason);
      console.log(reason);
      setVisible(false);
  });
  }

  function searchForPriority(production_order_number){
    if(production_order_number!=""){
        setDialogText("Hľadám");
        setVisible(true);
        let formData = new FormData();
        formData.append("production_order_number", production_order_number);
        postRequest(`/api/priorities/searchPriority`, formData,true)
        .then((response) => {
            setVisible(false);
            let priority = response.data;
            if(priority?.production_order_number){
                let form_id = null;
                if(formikPrio.values?.id)form_id = formikPrio.values.id;
                formikPrio.setValues(priority);
                formikPrio.setFieldValue("assignment_type","manually");
                formikPrio.setFieldValue("priority_department_id","");
                if(form_id)formikPrio.setFieldValue("id",form_id);
                console.log(formikPrio.values);
            }
            else{
                alert("Zákazka sa nenašla!");
            }
        });
    }
    else{
        alert("Nezadal si zákazku!");
    }
}

function sortByDeliveryDate(){
  ref.current?.clearSort();
  ref2.current?.clearSort();
  ref.current.setSort([
    
    {column:"delivery_date", dir:"asc"}, //sort by this first
    {column:"priority_department.defined_id", dir:"asc"},
  ]);
  ref2.current.setSort([
    {column:"delivery_date", dir:"asc"}, //sort by this first
    {column:"priority_department.defined_id", dir:"asc"},
  ]);
  setSortPriorities("delivery_date");
}
function sortByOwnPriority(){
  ref.current?.clearSort();
  ref2.current?.clearSort();
  ref.current.setSort([
    
    {column:"own_priority", dir:"desc"}, //sort by this first
    {column:"priority_department.defined_id", dir:"asc"},
  ]);
  ref2.current.setSort([
    {column:"own_priority", dir:"desc"}, //sort by this first
    {column:"priority_department.defined_id", dir:"asc"},
  ]);
  setSortPriorities("own_priority");
}

function changeSort(){
  if(sortPriorities == "own_priority")sortByDeliveryDate();
  else sortByOwnPriority();
}


    return(
      <>
<div className={showTable ? 'block px-1 bg-white shadow-md': 'hidden px-1 bg-white shadow-md'}>
  <div className='sticky w-full top-14 left-0 bg-white z-10 py-1'>
    <div className='grid grid-cols-2 gap-1 mt-0'>
  <select 
            name="abbreviation_id"
            value={selectedDepartment} onChange={(e) => setSelectedDepartment(e.target.value)}
            className={`bg-white block w-full mb-1 px-1 py-2 text-grey-700 border  focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
              <option value="">Všetky</option>
              {state.priority_departments.map((department) => (
                <option key={department.id} value={department.id}>{department.name}</option>
                ))}
              
          </select>
          <select 
            name="priority_state"
            value={selectedPriorityState} onChange={(e) => setSelectedPriorityState(e.target.value)}
            className={`bg-white block w-full mb-1 px-1 py-2 text-grey-700 border  focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
              <option value="Otvorené">Otvorené</option>
              <option value="Uzavreté">Uzavreté</option>
              <option value="Problémové">Problémové</option>
              <option value="Ručne pridané">Ručne pridané</option>
              <option value="Duplikáty">Duplikáty</option>
          </select>
          </div>
          <div className='w-full h-10 flex flex-row justify-evenly bg-white border focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
      <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={insertIcon} onClick={() => addNewPrio()}><AddIcon/></button></div>
      <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editPrio()}><EditIcon/></button></div>
      <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyPrio()}><CopyIcon/></button></div>
      <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
      <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={searchIconColor!="active"? () => setVisibleSearch(true):() => {setSearchIconColor("");setSearchValue("");showPriorities();}}>{searchIconColor == "" ?  <MdSearch fill='#7f1d1d' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Hľadať'/> : <MdSearchOff fill='#7f1d1d' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Zrušiť hľadanie'/>}</button></div>
      <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => refreshPriorities()}><RefreshIcon/></button></div>
      <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => changeSort()}>{sortPriorities == "own_priority" ? <PrioSort/> : <CalendarSort/>}</button></div>
      </div>
  </div>
<div className="flex flex-col overflow-x-auto no-scrollbar">
<div className="sm:-mx-6 lg:-mx-8 overflow-hidden">
  <div className="inline-block w-full sm:px-6 lg:px-8 h-[calc(100vh_-_150px)] overflow-y-auto">
        <div ref={priorityFromDepartmentRef}>
        <div onClick={() => {if(problemShowHideRef.current.style.display == "none")problemShowHideRef.current.style.display = "block";else problemShowHideRef.current.style.display = "none"}} className='flex flex-row bg-red-400 w-full'><span className='flex justify-start ml-2 text-lg font-semibold'>Problémy</span></div>
        <div ref={problemShowHideRef}>
        <ReactTabulator
        data={priorities} 
        className='max-h-[calc(100vh_-_205px)] whitespace-pre-wrap' //176px h-[calc(100vh_-_176px)]
        onRef={(r) => (ref2.current = r.current)}
        columns={problem_columns}
        events={{
          rowClick: rowClick,
          rowSelectionChanged: onSelectRow,
          rowMoveEvent: rowMoveProblemEvent,
          tableBuilt: ()=>{
            //priorityFromDepartmentRef.current.style.display = "none";
            //showPriorities(null);
          },
        }}
        
        options={{layout:"fitColumns",movableRows:user.permissions.includes("edit priorities"),rowHeight: 105, 
        initialSort:[
          {column:"own_priority", dir:"desc"},
          {column:"priority_department.defined_id", dir:"asc"},
        ],
        printAsHtml:true,printHeader:"<h1>Zoznam intervalov lakovne<h1>",printRowRange:"active"}}/> 
        </div>
      </div>
      <div ref={prioritiesRef} className=''>
      <div className='bg-green-400 w-full'><span className='ml-2 text-lg font-semibold'>Priority</span></div>
      <ReactTabulator
      data={priorities} 
      className='max-h-[calc(100vh_-_180px)] whitespace-pre-wrap' //176px h-[calc(100vh_-_176px)]
      onRef={(r) => (ref.current = r.current)}
      columns={priority_columns}
      events={{
        rowClick: rowClick,
        rowSelectionChanged: onSelectRow,
        rowMoved: rowMovePriorityEvent,
        tableBuilt: ()=>{
          //dispatch({type:'priorities_reload',value:true});
          showPriorities(null);
        }
      }}
      options={{layout:"fitColumns",movableRows:user.permissions.includes("edit priorities"),rowHeight: 105, 
      initialSort:[
        {column:"own_priority", dir:"desc"},
        {column:"priority_department.defined_id", dir:"asc"},
      ],
      printAsHtml:true,printHeader:"<h1>Zoznam intervalov lakovne<h1>",printRowRange:"active"}}/> 
    </div> 
  </div> 
</div> 
</div>
          <Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '85vw' }} onHide={() => setVisibleSearch(false)}>
            <div className="flex flex-col">
              <div className='flex w-full'>
              <input
                  value={searchValue} onChange={e => setSearchValue(e.target.value)}
                  autoFocus={visibleSearch ? true : false}
                  type="text"
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              </div>
              <div className='flex flex-row w-full justify-between'>
              <button type='button' onClick={() => barScannerSearch("search_value","pre hľadanie")}>
                <CiBarcode className='w-8 h-8 mr-2'/>
              </button>
              <button onClick={() => searchTable()} className='flex justify-center items-center w-12 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-600 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIconPrio/></button>
              </div>
            </div>
          </Dialog>
      <Dialog header="Stav" visible={visibleAddProblem} style={{ width: '80vw' }} onHide={() => hideAddProblem()}>
          <div className="card flex justify-content-center">
          <Dropdown inputId='state' name="state" value={formik.values.state} onChange={formik.handleChange} options={formikProblemState ? problem_states : (priorityForm?.state=="Nová" || priorityForm?.state=="Vyriešený problém") ? add_states : add_manager_states} optionLabel="name" placeholder="Vyber stav" 
              valueTemplate={selectedStateTemplate} itemTemplate={stateTemplate} className="w-full md:w-14rem border-2 border-black rounded-md"/>
          </div> 
          <form onSubmit={formik.handleSubmit}>
          {formik.values.state?.name=="Problém" && <>
            <div className='flex flex-col justify-center'>
              <div>
              <label 
              htmlFor='problem'
              className='text-lg font-semibold mb-2'>
              Popis:
              </label>
                <textarea rows={2} cols={30} 
                required
                name='problem'
                onChange={formik.handleChange} value={formik.values.problem}
                className="block w-full mb-2 px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                </textarea>
                <button type='button'
                  onTouchStart={startListening}
                  onMouseDown={startListening}
                  onTouchEnd={SpeechRecognition.stopListening}
                  onMouseUp={SpeechRecognition.stopListening}
                ><ActiveCopyIcon/></button>
                {listening && <div>Počúvam...</div>}
              </div>
              <div>
              <label 
              htmlFor="employee_department_id"
              className='text-lg font-semibold mb-2'>
              Oddelenie zadávateľa:
              </label>
                <select 
                disabled={!user.permissions.includes("edit priorities") ? true : false}
                onChange={(e) => filterEmployees(e.target.value)} value={employeeDepartment}
                name='employee_department_id'
                className='block w-full mb-2 px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`'>
                <option value="">Vyber oddelenie</option>
                {state.priority_departments
                  .map((department) => <option key={department.id} value={department.id}>{department.name}</option>)}
                </select>
              </div>
              <div>
              <label 
              htmlFor='contractor_id'
              className='text-xl font-semibold mb-2'>
              Meno zadávateľa:
              </label>
              <Select showSearch={true} optionFilterProp="children" value={formik.values.contractor_id || ""} onChange={(value) => formik.setFieldValue("contractor_id",value)} className='block w-full mb-3 mt-3 text-xl text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40' dropdownStyle={{zIndex:25000}} size="large">
              <Select.Option value="">Vyber zadávateľa</Select.Option>
              {employee?.map((employee) => (
                  <Select.Option key={employee.id} value={employee.id}>{employee.name}</Select.Option>
                  ))}
              </Select>
              {formik.errors.contractor_id ? <div style={{color:'red'}}>{formik.errors.contractor_id}</div> : null}
              </div>
              <div>
              <label className='text-lg font-semibold mb-2'>
                Dátum:
                </label>
                <input className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`" type='date' onChange={(e) => console.log(e.target.value)} value={DateTime.now().toFormat("yyyy-MM-dd").toString()} readOnly/>{/*value={DateTime.now().toFormat("d.M.y").toString()}*/}
              </div>
              <div>
              <label 
              htmlFor="offender_department_id"
              className='text-lg font-semibold mb-2'>
              Oddelenie vinníka:
              </label>
                <select 
                required
                onChange={formik.handleChange} value={formik.values.offender_department_id}
                name='offender_department_id'
                className='block w-full mb-2  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`'>
                <option value="">Vyber oddelenie</option>
                {state.priority_departments
                  .map((department) => <option key={department.id} value={department.id}>{department.name}</option>)}
                </select>
              </div>
            </div>
          </>}
          <button type="submit" disabled={formik.values.state==""?true:false} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť stav</button>
          </form>
          </Dialog>
          <Dialog header="Upraviť problém" visible={visibleUpdateProblem} style={{ width: '80vw' }} onHide={() => hideUpdateProblem()}>
          <div className='grid grid-rows-2 mb-2 py-0'>
            <span className='w-full font-semibold text-md flex'><span className='w-1/2 flex justify-items-start'>Výr. zákazka:</span><span className='w-1/2 flex justify-end'>{priorityForm?.production_order_number}</span></span>
            <span className='w-full font-semibold text-md flex'><span className='w-1/2 flex justify-items-start'>Pred. zákazka:</span><span className='w-1/2 flex justify-end'>{priorityForm?.sales_order_number}</span></span>
          </div>
          <div className="card flex justify-content-center">
              <Dropdown inputId='state' name="state" value={formikUpdate.values.state} onChange={formikUpdate.handleChange} options={states} optionLabel="name" placeholder="Vyber stav" 
              valueTemplate={selectedStateTemplate} itemTemplate={stateTemplate} className="w-full md:w-14rem border-2 border-black rounded-md mb-2"/>
          </div> 
          <form onSubmit={formikUpdate.handleSubmit}>
          {priorityProblem && <>
            <div className='w-full'>
              <div className='flex flex-col'>
              <div>
                <label 
                htmlFor="offender_department_id"
                className='text-lg font-semibold mb-2'>
                Oddelenie vinníka:
                </label>
                  <select 
                  onChange={formikUpdate.handleChange} value={formikUpdate.values.offender_department_id}
                  name='offender_department_id'
                  className='block w-full mb-2  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`'>
                  <option value="">Vyber oddelenie</option>
                  {state.priority_departments
                  .map((department) => <option key={department.id} value={department.id}>{department.name}</option>)}
                  </select>
                </div>
                <div className='mb-2'>
                <label 
                htmlFor='problem'
                className='text-lg font-semibold mb-2'>
                Popis:
                </label>
                  <textarea rows={2} cols={30} 
                  name='problem' readOnly
                  onChange={formikUpdate.handleChange} value={formikUpdate.values.problem}
                  className="block w-full mb-2 px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                  </textarea>
                  {listening && <div>Počúvam...</div>}
                </div>
                <div>
              <label 
              htmlFor="contractor_department_id"
              className='text-lg font-semibold mb-2'>
              Oddelenie zadávateľa:
              </label>
              <select required
                //value={priorityProblem?.contractor_department_id} 
                value={contractorDepartment}
                disabled
                name='contractor_department_id'
                className='block w-full mb-2 px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <option value="">Vyber oddelenie</option>
                {state.priority_departments
                  .map((department) => <option key={department.id} value={department.id}>{department.name}</option>)}
                </select>
              </div>
                <div>
                <label 
                htmlFor='contractor_id'
                className='text-lg font-semibold mb-2'>
                Meno zadávateľa:
                </label>
                  <select disabled
                  onChange={formikUpdate.handleChange} value={formikUpdate.values.contractor_id}
                  name='contractor_id'
                  className='block w-full mb-2 px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                  <option value="">Vyber zadávateľa</option>
                  {state.priority_departments_employees?.filter((item => item.abbreviation == priorityForm?.priority_department?.abbreviation))[0]?.employees
                  .filter((employee) => employee.date_of_departure == null || (employee.date_of_departure && DateTime.fromISO(employee.date_of_departure) < DateTime.now().toISO()))
                  .map((employee) => (
              <option key={employee.id} value={employee.id}>{employee.name}</option>
              ))}
                  </select>
                </div>
                <div>
                <label className='text-lg font-semibold mb-2'>
                  Dátum:
                  </label>
                  <input name="problem_created_at" value={formikUpdate.values.problem_created_at} readOnly className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" type='date'/>
                </div>
              </div>
              <div className='flex flex-col'>
                <div>
                <label 
                htmlFor='solution'
                className='text-lg font-semibold mb-2'>
                Riešenie:
                </label>
                  <textarea rows={2} cols={30} required
                  name='solution'
                  onChange={formikUpdate.handleChange} value={formikUpdate.values.solution}
                  className="block w-full mb-2 px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                  </textarea>
                  <button type='button'
                    onTouchStart={startListening}
                    onMouseDown={startListening}
                    onTouchEnd={SpeechRecognition.stopListening}
                    onMouseUp={SpeechRecognition.stopListening}
                  >🎤</button>
                  {listening && <div>Počúvam...</div>}
                </div>
                <div>
                <label 
                htmlFor='solver_department_id'
                className='text-lg font-semibold mb-2'>
                Oddelenie riešiteľa:
                </label >
                <select 
                disabled={!user.permissions.includes("edit priorities") ? true : false}
                onChange={(e) => filterSolvers(e.target.value)} value={solverDepartment}
                name='solver_department_id'
                required={(formikUpdate.values.solution!="" && formikUpdate.values.solution!=null) ? true : false}
                className="block w-full mb-2 px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                <option value="">Vyber oddelenie</option>
                {state.priority_departments
                  .map((department) => <option key={department.id} value={department.id}>{department.name}</option>)}
                </select>
                </div>
                <div>
                <label 
                htmlFor='solver_id'
                className='text-xl font-semibold mb-2'>
                Meno riešiteľa:
                </label >
                  <Select showSearch={true} optionFilterProp="children" value={formikUpdate.values.solver_id ? formikUpdate.values.solver_id : ""} onChange={(value) => formikUpdate.setFieldValue("solver_id",value)} className='block w-full mb-3 mt-3 text-xl text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40' dropdownStyle={{zIndex:25000}} size="large">
                  <Select.Option value="">Vyber riešiteľa</Select.Option>
                  {solver?.map((employee) => (
                      <Select.Option key={employee.id} value={employee.id}>{employee.name}</Select.Option>
                      ))}
                  </Select>
                  {formikUpdate.errors.solver_id ? <div style={{color:'red'}}>{formikUpdate.errors.solver_id}</div> : null}
                  </div>
                <div>
                <label className='text-lg font-semibold mb-2'>
                  Dátum:
                  </label>
                  <input className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`" type='date' onChange={(e) => console.log(e.target.value)} value={DateTime.now().toFormat("yyyy-MM-dd").toString()} readOnly/>
                </div>
              </div>
            </div>
          </>}
          <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-red-950 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>Uložiť stav</button>
          {user.permissions.includes("edit priorities") && <button type="button" onClick={confirmProblemDelete} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-red-800 rounded-md hover:bg-red-700 focus:outline-none focus:bg-rose-600 disabled:opacity-25'>Zmazať problém</button>}
          </form>
          </Dialog>
      </div>
      <form method='post' onSubmit={formikPrio.handleSubmit} className={showForm ? 'block' : 'hidden' }>
        <div className='sticky top-14 left-0 w-full h-10 flex flex-row justify-evenly border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => {showPriorities(null);setShowTable(true);setShowForm(false);formikPrio.resetForm();}}><PrioBack/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => {setSaveAndClose(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
        </div>
      <div className='bg-white'>
      <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
          <div className="w-auto p-6 bg-white  shadow-md">
            <h1 className="text-2xl font-semibold text-left text-zinc-900">
                Ručne zadaná priorita
            </h1>
            <div>
                        <label
                            htmlFor="production_order_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Výrobná zákazka
                        </label>
                        <input
                            required
                            name='production_order_number'
                            type="text"
                            onChange={formikPrio.handleChange} value={formikPrio.values.production_order_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <div className='flex flex-row mb-2'>
                            <button type='button' onClick={() => barScanner("production_order_number","výrobnej zákazky")}>
                            <CiBarcode className='w-8 h-8 mr-2'/>
                            </button>
                            <button type='button' className='wrapIcon flex flex-row border mt-1 border-gray-400 rounded-md bg-white px-1 py-1 hover:text-white hover:!stroke-white hover:bg-red-900 hover:border-red-900' onClick={() => searchForPriority(formikPrio.values.production_order_number)}>
                            <LoadPrioIcon/>
                            <span className='ml-2'>Načítať z terminplánu</span>
                            </button>
                        </div>
                        </div>
                        <div>
                        <label
                            htmlFor="sales_order_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Predajná zákazka
                        </label>
                        <input
                            name='sales_order_number'
                            type="text"
                            onChange={formikPrio.handleChange} value={formikPrio.values.sales_order_number}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <div className='flex flex-row items-center'>
                        <button type='button' onClick={() => barScanner("sales_order_number","predajnej zákazky")}>
                            <CiBarcode className='w-8 h-8 mr-2'/>
                            </button>
                        </div>
                        </div>
                      <div>
                        <label
                            htmlFor="priority_department_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie
                        </label>
                        <select
                            required
                            name='priority_department_id'
                            type="text"
                            onChange={formikPrio.handleChange} value={formikPrio.values.priority_department_id}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        <option value="">Vyber oddelenie</option>
                        {state.priority_departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                        ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="article_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Artikel
                        </label>
                        <input
                            name='article_number'
                            type="text"
                            onChange={formikPrio.handleChange} value={formikPrio.values.article_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="delivery_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dodací termín
                        </label>
                        <input
                            name='delivery_date'
                            type="date"
                            onChange={formikPrio.handleChange} value={formikPrio.values.delivery_date}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>             
                <div>
                        <label
                            htmlFor="description_1"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov artikla 
                        </label>
                        <input
                            name='description_1'
                            type="text"
                            onChange={formikPrio.handleChange} value={formikPrio.values.description_1}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="description_2"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Popis artikla
                        </label>
                        <input
                            name='description_2'
                            type="text"
                            onChange={formikPrio.handleChange} value={formikPrio.values.description_2}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="drawing_url"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Link výkresu
                        </label>
                        <input
                            name='drawing_url'
                            type="text"
                            onChange={formikPrio.handleChange} value={formikPrio.values.drawing_url}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="drawing_name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Číslo výkresu
                        </label>
                        <input
                            name='drawing_name'
                            type="text"
                            onChange={formikPrio.handleChange} value={formikPrio.values.drawing_name}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="total_amount"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Počet kusov
                        </label>
                        <input
                            name='total_amount'
                            type="number"
                            onChange={formikPrio.handleChange} value={formikPrio.values.total_amount}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
          </div>
      </div>
      <button type="submit" ref={submitRef} className='hidden'></button> 
      </div> 
      </form>
      <div className={showBarScanner ? 'block bg-white h-[calc(100vh_-_80px)]' : 'hidden' }>
      <div className='sticky top-14 left-0 w-full h-10 flex flex-row justify-start border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
        <div className='flex flex-col w-20 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={ scanValue=="search_value" ? () => {setShowTable(true);setShowBarScanner(false);setVisibleSearch(true);} : () => {setShowTable(false);setShowBarScanner(false);setShowForm(true);}}><span className='w-full flex flex-row'><PrioBack/><p className='text-red-950 font-semibold'>Späť</p></span></button></div>
      </div>
      <div>
        <div id="interactive" className="viewport">
          <h1 className='text-xl font-semibold text-center mb-2 text-zinc-900'>Skenovanie {scanTitle}</h1>
          <video className="max-w-full w-auto m-auto px-4 border-red-950 rounded-md" autoplay="true" preload="auto" src="" muted="true"
             playsinline="true"/>
          <canvas className="drawingBuffer hidden"></canvas>
        </div>
      </div>
      </div>
 </>
    )

}

/*useEffect(() => {
      console.log("PRIORITY RELOAD",state.priorities_reload);
      if (state.priorities && ref.current && state.priorities_reload==true) {
        dispatch({type:'priorities_reload',value:false});
        console.log("RELOAD");
        if (selectedDepartment!="") {
          ref.current.replaceData(state.priorities.filter((priority) => priority.priority_department_id == selectedDepartment));
          ref.current.showColumn("move");
          console.log(ref.current.getData());
        }
        else{
          console.log(selectedDepartment);
          ref.current.replaceData(state.priorities);
          ref.current.hideColumn("move");
        }
      }
    },[state.priorities,ref.current,state.priorities_reload]);*/