import React, { useContext, useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Outlet, Link } from "react-router-dom";
import { IoLogOut } from "react-icons/io5";
import AuthContext from './AuthContext';
import { DatabaseContext } from '../redux/DatabaseStore';
import { Badge } from 'primereact/badge';
import UtilContext from './UtilContext';

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const { logout } = useContext(AuthContext);
    const [state,dispatch] = useContext(DatabaseContext);
    const {setVisibleNotifications} = useContext(UtilContext);
    const handleNav = () => {
        setNav(!nav);
    }

    const navItems = [
      { id: 1, text: 'Domov', link:"/" },
      { id: 2, text: 'Priority', link:"/priorities" },
      { id: 3, text: 'OTK', link:"/otkhome" },
      { id: 4, text: 'Lakovňa', link:"/paintinghome" },
      { id: 4, text: 'Kalibrácia', link:"/calibrationhome" },
    ];

    /*{ id: 3, text: 'OTK', link:"/otkhome" },
      { id: 4, text: 'Lakovňa', link:"/paintinghome" },
      { id: 5, text: 'Kalibrácia' },
    { id: 6, text: 'Školenie' },
    */

  return (
    <div className='sticky bg-red-950 justify-between items-center h-14 w-full mx-auto px-4 text-white top-0 left-0 z-20'>
      <div className='flex items-center justify-start'>
        <img src={require('../assets/img/logo.png')} alt='Schelling Logo' className='h-12 w-12 px-2 py-2 object-cover'></img>
        <h1 className='w-full text-3xl font-bold text-white text-left py-1'>ISG-SK</h1>
        <div className='flex items-center '>
        {/*Desktop Navigation*/}
        <ul className='hidden md:flex flex-row '>
        {navItems.map(item => (
          <Link to={item.link}>
          <li
            key={item.id}
            className='px-2 py-1 hover:bg-red-800 rounded-xl m-2 cursor-pointer duration-300 hover:text-black'
          >
          {item.text}
          </li>
          </Link>
        ))}
        </ul>
        </div>
        <div className='flex'>
        <button
            type="button"
            className="flex items-center rounded-full w-8 h-8  p-1 pt-3 mr-2 text-gray-400 hover:text-white z-30"
            onClick={() => setVisibleNotifications(true)}
          >
            <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '1.4rem'}}>
              {state.notifications?.length>0 && <Badge value={state.notifications.length}></Badge>}
            </i>
            <span className="sr-only">View notifications</span>
            {/*<BellIcon className="h-6 w-6" aria-hidden="true" />*/}
          </button>
        </div>
        <div onClick={handleNav}
            className='flex flex-row items-center md:hidden mt-1'>
                {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
      </div>


        {/*Mobile Navigation Icon*/}
        

        {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? 'fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-600 bg-red-950 ease-in-out duration-500'
            : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
        }
      >
        {/* Mobile Logo */}
        <div className='flex justify-center items-center'>
            <img src={require('../assets/img/png_nocolor.png')} alt='Schelling Logo' className='h-auto max-w-24 px-2 py-2 object-cover'></img>
        </div>

        {/* Mobile Navigation Items */}
        {navItems.map(item => (
          <Link to={item.link} onClick={handleNav}>
          <li
            key={item.id}
            className='p-4 border-b rounded-xl hover:bg-red-800 duration-500 hover:text-black cursor-pointer border-gray-600'
          >{item.text}
          </li>
          </Link>
        ))}
        <li className='p-4 border-b rounded-xl hover:bg-red-800 duration-500 hover:text-black cursor-pointer border-gray-600'>
        <button
        onClick={() => logout()}>
        <div className='flex items-center justify-left'><IoLogOut
         className='mr-2'/>Odhlásenie</div></button>
        </li>
      </ul>
    </div>
  )
};
export default Navbar;