import React, {useContext, useState, useRef, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { SaveAndCloseIcon,SaveIcon, PrioBack,} from "../svg/svgIcons";
import { CiBarcode } from "react-icons/ci";
import UtilContext from '../../components/UtilContext';
import Quagga from 'quagga';
import { useFormik } from 'formik'; 
import { getRequest, postRequest, deleteRequest } from '../../api/ApiController';

export default function OTKSearch(){

    const {toast,visible,setDialogText,setVisible}= useContext(UtilContext);

    const navigate = useNavigate();

    const [showDefault,setShowDefault] = useState(true);
    const [showBarScanner, setShowBarScanner] = useState(false);

    const [scanValue,setScanValue] = useState("");
    const [scanTitle,setScanTitle] = useState("");

    const [fileList, setFileList] = useState([]);

    const fileListRef = useRef(null);
    const submitRef = useRef(null);

    function barScanner(scanType,title){
        setShowBarScanner(true);
        setShowDefault(false);
        setScanValue(scanType);
        setScanTitle(title);
      }

      const stopScanner = () => {
        Quagga.offProcessed();
        Quagga.offDetected();
        Quagga.stop();
    };

      const formik = useFormik({
        initialValues: {
            order_number:'',type:'',
        },
        enableReinitialize:true,
        onSubmit: values => {
          /*setDialogText("Ukladám");
          setVisible(true);
          let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            for(let i = 0;i<fileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',fileList[i]);
            }
            if(values.type=="Interná chyba" || values.type=="Externá chyba" || values.type=="Reklamácia"){
                postRequest('/api/error_records', formData,true)
                .then((response) => {
                    console.log(response);
                    formik.resetForm();
                    setFileList([]);
                    fileListRef.current.value = null;
                    toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                }).catch((reason) => {
                    alert("Daný úkon sa nepodaril!");
                })
            }
            else{
            }*/
        },
    });

    useEffect(() => {
        if(scanValue!=""){
        const _onDetected = res => {
            stopScanner();
            if(scanValue=="order_number"){
                formik.setFieldValue("order_number",res.codeResult.code);
            }
            setScanValue("");
            setShowBarScanner(false);
            setShowDefault(true);
  
        };
        /* start camera */
        Quagga.init(
        {
            inputStream: {
            type: "LiveStream",
            constraints: {
                facingMode: "environment", // or user
            },
            },
            locator: {
            patchSize: "medium",
            halfSample: false,
            },
            numOfWorkers: 2,
            frequency: 10,
            decoder: {
            readers: ["code_128_reader"],
            debug: {
                drawBoundingBox: false,
                showFrequency: false,
                drawScanline: false,
                showPattern: false,
            },
            multiple: false,
            },
            locate: true,
        },
        function (err) {
            if (err) {
            return console.log(err);
            }
            Quagga.start();
        }
        );
        Quagga.onDetected(_onDetected);
        }
      }, [scanValue, formik]);
    return(
        <>
        <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-col bg-white h-[calc(100vh_-_56px)] pb-2'>
        {showDefault && <><div className='sticky top-14 left-0 w-full h-10 flex flex-row border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <div className='flex flex-row w-10 h-10 px-2 py-1 justify-left'><button className="disabled:opacity-25"  type='button' onClick={() => navigate("/otkhome")}><PrioBack/></button></div>
        </div></>}
            <div className='w-auto px-4 mx-2'>
            {showDefault && <> <h1 className='w-full text-2xl font-bold mb-4 text-red-900'>VYHĽADÁVANIE</h1>
                <div className='w-full mb-2'>
                    <label htmlFor='type' className='font-semibold'>
                        Vyhľadať z:
                        <select className='w-full h-10 border border-black rounded-md'
                        name="type"
                        onChange={formik.handleChange} value={formik.values.type}
                        >
                            <option value="Dokumentácia" >Dokumentácia</option>
                            <option value="Sebakontrola" >Sebakontrola</option>
                            <option value="Merací protokol" >Merací protokol</option>
                            <option value="Žihací protokol" >Žihací protokol</option>
                            <option value="Interná chyba" >Interná chyba</option>
                            <option value="Externá chyba" >Externá chyba</option>
                            <option value="Reklamácia" >Reklamácia</option>
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='order_number' className='font-semibold '>
                        Zákazka:
                        <input 
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='order_number'
                        onChange={formik.handleChange} value={formik.values.order_number}
                        />
                        <div className='flex flex-row items-center'>
                        <button type='button' onClick={() => barScanner("order_number","zákazky")}>
                            <CiBarcode className='w-8 h-8 mr-2'/>
                            </button>
                        </div>
                    </label>
                </div>
                    <button type="submit" className='bg-red-950 w-full p-2 text-white font-semibold rounded-md hover:bg-gray-800'>HĽADAŤ</button>
                    </>}
                </div>
                <div className={showBarScanner ? 'block bg-white h-[calc(100vh_-_56px)]' : 'hidden' }>
      <div className='sticky top-14 left-0 w-full h-10 flex flex-row justify-start border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
        <div className='flex flex-col w-20 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => {setShowDefault(true);setShowBarScanner(false);}}><span className='w-full flex flex-row'><PrioBack/><p className='text-red-950 font-semibold'>Späť</p></span></button></div>
      </div>
      <div>
        <div id="interactive" className="viewport">
          <h1 className='text-xl font-semibold text-center mb-2 text-zinc-900'>Skenovanie {scanTitle}</h1>
          <video className="max-w-full w-auto m-auto px-4 border-red-950 rounded-md" autoplay="true" preload="auto" src="" muted="true"
             playsinline="true"/>
          <canvas className="drawingBuffer hidden"></canvas>
        </div>
      </div>
      </div>
            </div>
        </form>
        </>
    )
}