import {React, useContext, useState} from 'react';
import AuthContext from "./components/AuthContext";
import { TbRulerMeasure } from "react-icons/tb";
import { CiPalette } from "react-icons/ci";
import { MdCompassCalibration } from "react-icons/md";
import { MdModelTraining } from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import { Outlet, Link } from "react-router-dom";
import { PrioIcon, PenRulerIcon, TrainingIcon, BrushIcon, CalIcon } from './pages/svg/svgIcons';

export default function HomePage(){

  const { user, logout } = useContext(AuthContext);
  const [count, setCount] = useState(0);

return (
<div className='flex flex-col px-2 pb-2 h-[calc(100vh_-_56px)] pt-4 bg-gray-100'>
<div className='w-full flex justify-center items-center'>
        <Link 
          to="/priorities" className='bg-white w-full h-24 text-3xl rounded-md text-red-900 px-2 py-2 mb-4 hover:bg-gray-300 shadow-xl shadow-gray-300'>
          <div className='flex items-center justify-left'><PrioIcon/><h1 className='font-bold'>Priority</h1> </div>
          <p className='flex justify-left text-sm'>Zadanie priorít</p>
        </Link>
      </div>
      <div className='w-full flex justify-center items-center'>
        <Link to="/otkhome" className='bg-white w-full h-24 text-3xl text-red-900 rounded-md px-2 py-2 mb-4 hover:bg-gray-200 shadow-xl'>
          <div className='flex items-center justify-left'><PenRulerIcon/><h1 className='font-bold'>OTK</h1> </div>
          <p className='flex justify-left text-sm'>Zadanie dokumentácie</p>
        </Link>
      </div>
      <div className='w-full flex justify-center items-center'>
        <Link to="/paintinghome" className='bg-white w-full h-24 text-3xl text-red-900 rounded-md px-2 py-2 mb-4 hover:bg-gray-200 shadow-xl'>
        <div className='flex items-center justify-left'><BrushIcon
         className='mr-4'/><h1 className='font-bold'>Lakovňa</h1></div>
         <p className='flex justify-left text-sm'>Zadanie podmienok lakovania</p></Link>
      </div>
      <div className='w-full flex justify-center items-center'>
      <Link to="/calibrationhome" className='bg-white w-full h-24 text-3xl text-red-900 rounded-md px-2 py-2 mb-4 hover:bg-gray-200 shadow-xl'>
        <div className='flex items-center justify-left'><CalIcon
         className='mr-4'/><h1 className='font-bold'>Kalibrácia</h1></div>
         <p className='flex justify-left text-sm'>Zadanie meradla a kalibrácií</p></Link>
      </div>
      {/*<div className='w-full flex justify-center items-center'>
        <button className='bg-white w-full h-24 text-3xl text-red-900 rounded-md px-2 py-2 mb-4 hover:bg-gray-200 shadow-xl'>
        <div className='flex items-center justify-left'><TrainingIcon
         className='mr-4'/><h1 className='font-bold'>Školenie</h1></div>
         <p className='flex justify-left text-sm'>Zadanie školení zamestnancov</p></button>
      </div>*/}
      <div className='w-full flex justify-center items-center'>
        <button className='bg-white w-full h-24 text-3xl text-red-900 rounded-md px-2 py-2 mb-4 hover:bg-gray-200 shadow-xl'
        onClick={() => logout()}>
        <div className='flex items-center justify-left'><IoLogOut
         className='mr-2'/><h1 className='font-bold'>Odhlásenie</h1></div></button>
  </div>
    </div>
)
}