import React, {useContext, useState, useRef, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { CiBarcode } from "react-icons/ci";
import { SaveAndCloseIcon,SaveIcon, PrioBack,} from "../svg/svgIcons";
import { useFormik } from 'formik'; 
import UtilContext from '../../components/UtilContext';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import AuthContext from '../../components/AuthContext';

export default function PaintingMeasure(painting_condition,type){

    const navigate = useNavigate();

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,visible,setDialogText,setVisible}= useContext(UtilContext);

    const [formPaintingCondition,setFormPaintingCondition] = useState(painting_condition);
    const [formType, setFormType] = useState(type);
    const [checkTimeCondition, setCheckTimeCondition] = useState(false);

    const [fileList, setFileList] = useState([]);
    const [saveAfterClose,setSaveAfterClose] = useState(false);

    const fileListRef = useRef(null);
    const submitRef = useRef(null);

    useEffect(() => {
        if(!checkTimeCondition){
            setCheckTimeCondition(true);
            setDialogText("Overujem podmienky");
            setVisible(true);
            getRequest(`/api/painting_conditions/checkTimeConditions`)
            .then((response) => {
                if(response.status == 200){
                    setVisible(false);
                }
                else{
                    setVisible(false);
                    alert("Si mimo intervalu pre vloženie!");   
                    navigate("/paintinghome");
                }
            }).catch((reason) => {
                setVisible(false);
                alert("Nepodarilo sa overiť pomienky!");
            })
        }
      },[checkTimeCondition]);

    function loadOutdoorWeather() {
        var key = '24110dc31c01b76eeef9d9264d0483e8';
        //fetch('https://api.openweathermap.org/data/2.5/weather?id=865084&units=metric&lang=sk&appid=' + key)  
        fetch('https://api.openweathermap.org/data/2.5/weather?lat=48.559885&lon=21.266370&units=metric&lang=sk&appid=' + key)
        .then(function(resp) { return resp.json() }) // Convert data to json
        .then(function(data) {
          console.log(data);
          formik.setFieldValue("outdoor_temp",Math.round(data.main.temp));
          formik.setFieldValue("outdoor_hum",Math.round(data.main.humidity));
        })
        .catch(function() {
          // catch any errors
        });
    }

        const formik = useFormik({
            initialValues: {
                outdoor_temp: '',indoor_temp:'',outdoor_hum:'',indoor_hum:'',weather:'',ph_probe:'OK',ph_phosphate:'OK',note:'',temp_check:'OK',nacl_check:'OK',state:'',
            },
            enableReinitialize:true,
            onSubmit: values => {
                let formData = new FormData();
                //let ph_phosphate = state.painting_intervals.filter(interval => interval.id==values.ph_phosphate);
                //let temp_check = state.painting_intervals.filter(interval => interval.id==values.temp_check);
                if(values.ph_probe=='OK' && values.nacl_check=='OK' && values.ph_phosphate=='OK' && values.temp_check=='OK'){ 
                    values.state='OK';
                }
                else {
                    values.state='NOK';
                }
                for (let value in values) {
                    formData.append(value, values[value]);
                }
                    postRequest('/api/painting_conditions', formData,true)
                    .then((response) => {
                        console.log(response);
                        formik.resetForm();
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                        if(saveAfterClose)navigate("/paintinghome");
                        setVisible(false);
                    }).catch((reason) => {
                        alert("Daný úkon sa nepodaril!");
                        setVisible(false);
                    })
            },
         });

    return(
        <form className='w-full h-full' onSubmit={formik.handleSubmit}>
            <div className='flex flex-col bg-white h-full pb-2'>
            <div className='sticky top-14 left-0 w-full h-10 flex flex-row justify-evenly border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => navigate("/paintinghome")}><PrioBack/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => {setSaveAfterClose(true);submitRef.current.click()}}><SaveAndCloseIcon/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => submitRef.current.click()}><SaveIcon/></button></div>
        </div>
                <div className='w-auto p-4 m-2'>
                    <h1 className='w-full text-2xl font-bold mb-4 text-red-900'>NOVÉ MERANIE</h1>
                    <div className='flex flex-row m-autojustify-left mt-2'>
                    <div className='mr-2'>
                    <svg className="w-6 h-6 text-gray-800 dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 15a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0-11a1 1 0 0 0 1-1V1a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1Zm0 12a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1ZM4.343 5.757a1 1 0 0 0 1.414-1.414L4.343 2.929a1 1 0 0 0-1.414 1.414l1.414 1.414Zm11.314 8.486a1 1 0 0 0-1.414 1.414l1.414 1.414a1 1 0 0 0 1.414-1.414l-1.414-1.414ZM4 10a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1Zm15-1h-2a1 1 0 1 0 0 2h2a1 1 0 0 0 0-2ZM4.343 14.243l-1.414 1.414a1 1 0 1 0 1.414 1.414l1.414-1.414a1 1 0 0 0-1.414-1.414ZM14.95 6.05a1 1 0 0 0 .707-.293l1.414-1.414a1 1 0 1 0-1.414-1.414l-1.414 1.414a1 1 0 0 0 .707 1.707Z"/>
                    </svg>
                    </div>
                    <button type="button" onClick={() => loadOutdoorWeather()} >
                    <h3 className='text-xl font-light text-left text-zinc-900 mb-2 hover:text-red-600'>Nahrať údaje z internetu</h3>
                    </button>
                </div>
                    <div className='w-full mb-2'>
                    <label htmlFor='outdoor_temp' className='font-semibold '>
                        Vonkajšia teplota v °C:
                        <input 
                        className='w-full p-2 border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        type='text'
                        name='outdoor_temp'
                        onChange={formik.handleChange} value={formik.values.outdoor_temp}
                        />
                    </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label htmlFor='outdoor_hum' className='font-semibold '>
                        Vonkajšia vlhkosť v %:
                        <input 
                        className='w-full p-2 border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        type='text'
                        name='outdoor_hum'
                        onChange={formik.handleChange} value={formik.values.outdoor_hum}
                        />
                    </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label htmlFor='indoor_temp' className='font-semibold '>
                        Vnútorná teplota v °C:
                        <input 
                        className='w-full p-2 border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        type='text'
                        name='indoor_temp'
                        onChange={formik.handleChange} value={formik.values.indoor_temp}
                        />
                    </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label htmlFor='indoor_hum' className='font-semibold '>
                        Vnútorná vlhkosť v %:
                        <input 
                        className='w-full p-2 border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        type='text'
                        name='indoor_hum'
                        onChange={formik.handleChange} value={formik.values.indoor_hum}
                        />
                    </label>
                    </div>
                    <div>
                        <label
                            htmlFor="weather"
                            className='font-semibold'
                        >
                            Počasie:
                        <select 
                        name='weather'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.weather}
                        >
                          <option value="">Vyber počasie</option>
                            {state.weather.map((weather) => 
                            <option value={weather.type} key={weather.id}>{weather.type}</option>
                            )}
                        </select>
                        </label>
                    </div> 
                    <div>
                        <label
                            htmlFor="ph_probe"
                            className='font-semibold'
                        >
                            Kontrola čistoty pH sondy:
                        <select 
                        name='ph_probe'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.ph_probe}
                        >
                          <option value="OK">OK</option>
                          <option value="NOK">NOK</option>
                        </select>
                        </label>
                    </div> 
                    <div>
                        <label
                            htmlFor="ph_phosphate"
                            className='font-semibold'
                        >
                            Kontrola pH fosfátu:
                        <select 
                        name='ph_phosphate'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.ph_phosphate}
                        >
                          <option value="">Zvoľ hodnotu</option>
                          {state.painting_intervals.filter(interval=>interval.type=='PH').map((painting_interval) => (
                            <option key={painting_interval.id} value={painting_interval.state}>{painting_interval.name}</option>
                          ))}
                        </select>
                        </label>
                    </div> 
                    <div>
                        <label
                            htmlFor="temp_check"
                            className='font-semibold'
                        >
                            Kontrola teploty WAP:
                        <select 
                        name='temp_check'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.temp_check}
                        >
                          <option value="">Zvoľ hodnotu</option>
                          {state.painting_intervals.filter(interval=>interval.type=='WAP').map((painting_interval) => (
                            <option key={painting_interval.id} value={painting_interval.state}>{painting_interval.name}</option>
                          ))}
                        </select>
                        </label>
                    </div> 
                    <div>
                        <label
                            htmlFor="nacl_check"
                            className='font-semibold'
                        >
                            Kontrola NaCl v sude:
                        <select 
                        name='nacl_check'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.nacl_check}
                        >
                          <option value="OK">OK</option>
                          <option value="NOK">NOK</option>
                        </select>
                        </label>
                    </div> 
                    <div className='w-full mb-2'>
                    <label htmlFor='outdoor_temp' className='font-semibold '>
                        Poznámka:
                        <textarea className='w-full p-2 border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        name='note'
                        onChange={formik.handleChange} value={formik.values.note}
                        >
                        </textarea>
                    </label>
                    </div>
                </div>
            </div>
            <button type="submit" ref={submitRef} className='hidden'></button>
        </form>
    )
}