import React, {useContext, useState, useRef, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { SaveAndCloseIcon,SaveIcon, PrioBack,DeleteIcon} from "../svg/svgIcons";
import { DatabaseContext } from '../../redux/DatabaseStore';
import UtilContext from '../../components/UtilContext';
import { getRequest, postRequest, deleteRequest } from '../../api/ApiController';
import { Select } from "antd";
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useFormik } from 'formik';
import "../../assets/css/styles.css"

export default function CalibrationSearch({gauge}){

    const [state,dispatch] = useContext(DatabaseContext);
    const {toast,visible,setDialogText,setVisible}= useContext(UtilContext);

    const navigate = useNavigate();

    const [showCalDetail,setShowCalDetail] = useState(false);
    const [formGauge, setFormGauge] = useState("");


    const [selectedGauge,setSelectedGauge] = useState("");
    const [visibleFiles,setVisibleFiles] = useState(false);
    const [visibleCalibration,setVisibleCalibration] = useState(false);
    const [images,setImages] = useState('');
    const [viewedImage,setViewedImage] = useState('');
    const [saveAfterClose,setSaveAfterClose] = useState(false);
    const [selectedCalibration,setSelectedCalibration] = useState(null);

    const [fileList, setFileList] = useState([]);

    const fileListRef = useRef(null);
    const submitRef = useRef(null);

    const accept = (id) => {
        getRequest(`/api/error_records/deleteImage/${id}`)
            .then((response) => {
              toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
              getImages();
            }).catch((reason) => {
                alert(reason);
                toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
            })
      };

      const reject = () => {
          toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
      }

    function deleteFiles(img){
        confirmDialog({
            message: 'Prajete si vymazať tento súbor?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept:reject,
            reject:() => accept(img.id)
        });
    }

    function getImages(id){
        getRequest(`/api/gauge_calibrations/protocols/${id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setImages(response.data);
        });
    }

    function showCalInfo(){
        let calibration = state.gauges.filter((gauge) => gauge.id == selectedGauge);
        if(calibration.length>0 && calibration[0].gauge_calibrations.length>0){
            setSelectedCalibration(state.gauges.filter((gauge) => gauge.id == selectedGauge)[0].gauge_calibrations[0]);
            getImages(state.gauges.filter((gauge) => gauge.id == selectedGauge)[0].gauge_calibrations[0].id);
        }
        else{
            setSelectedCalibration(null);
        }
        setShowCalDetail(true);
    }

        /* validate form */
        const validate = values => {

        };

    const formik = useFormik({
        initialValues:{
            validator_id: '',calibration_date: '',calibration_number_protocol:'',verification_result:'OK',contractor_id:'',description:'',},
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            if(values.validator_id == "Interne"){
                values.validator_id = null;
            }
            for (let value in values) {
                formData.append(value, values[value]);
            }
            for(let i = 0;i<fileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',fileList[i]);
            }
            formData.append("gauge_id", selectedGauge);
            setDialogText("Ukladám");
            setVisible(true);
            postRequest('/api/gauge_calibrations', formData,true)
            .then((response) => {
                getRequest('/api/gauges').then((response) => {
                console.log(response);
                formik.resetForm();
                toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                if(saveAfterClose)navigate("/calibrationhome");
                setVisible(false);
                });
            }).catch((reason) => {
                alert("Daný úkon sa nepodaril!");
                setVisible(false);
            })
        },
    });

    const handleFileChange = (event) => {
        setFileList(event.target.files);
    };

    function hideCalDialog(){
        formik.resetForm();
        setVisibleCalibration(false);
    }


    return(
        <>
        <div className='flex flex-col bg-white h-[calc(100vh_-_56px)] pb-2'>
            <div className='sticky top-14 left-0 w-full h-10 flex flex-row border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <div className='flex flex-row w-10 h-10 px-2 py-1 justify-left'><button className="disabled:opacity-25"  type='button' onClick={() => navigate("/calibrationhome")}><PrioBack/></button></div>
        </div>
            <div className='w-auto px-4 mx-2'>
                <h1 className='w-full text-2xl font-bold mb-4 text-red-900'>VYHĽADÁVANIE KALIBRÁCIE</h1>
                <div className='flex flex-col w-full mb-2'>
                    <label htmlFor='order_number' className='font-semibold '>
                        Názov meradla:
                        </label>
                        <Select style={{ width: 325, height:42,fontSize:14, }} showSearch={true} optionFilterProp="children" value={selectedGauge} onChange={(value) => setSelectedGauge(value)}>
                        <Select.Option value="">Vyber meradlo</Select.Option>
                        {state.gauges.map((gauge) => (
                            <Select.Option key={gauge.id} value={gauge.id}>{gauge.gauge_name}</Select.Option>
                            ))}
                        </Select>
                </div>
                    <button type="button" onClick={() => showCalInfo()} className='bg-red-950 w-full p-2 mb-6 text-white font-semibold rounded-md hover:bg-red-700'>VYBRAŤ</button>
                </div>
                {showCalDetail && <>
                    <h1 className='w-full text-2xl text-center font-bold mb-4 text-red-900'>INFORMÁCIE O MERADLE</h1>
                    <div className='flex flex-col w-auto px-4 mx-2 mb-2'>
                    <label className='font-semibold '>Platnosť kalibrácie do:</label>
                    <input 
                    disabled
                    className='w-full p-2 border border-gray-200 rounded-md'
                    type='text'
                    name='next_calibration_date'
                    value={selectedCalibration ? selectedCalibration.next_calibration_date : ""} />
                </div>
                <div className='flex flex-col w-auto px-4 mx-2 mb-2'>
                    <label className='font-semibold '>Kalibračný protokol:</label>
                    {images!=='' ? images.map((img) => (<div className='flex flex-row p-2'>
                        <div onClick={() => setVisibleFiles(true)}>
                            <iframe src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></iframe>
                        </div>
                        <div className='flex flex-col ml-4'>
                            <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                            {/*<button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/><span className='text-white ml-1'>Vymazať</span></button>*/}
                        </div>
                    </div>)) : <span>Načítavam...</span>}
                    {images?.length == 0 && <span>Neobsahuje kalibračný protokol!</span>}
                </div>
                <div className='flex flex-col w-auto px-4 mx-2'>
                <button type="button" onClick={() => setVisibleCalibration(true)} className='bg-red-950 w-full p-2 text-white font-semibold rounded-md hover:bg-red-700'>NAHRAŤ NOVÚ KALIBRÁCIU</button>
                </div>
                </>}
            </div>
            <Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
            <iframe src={viewedImage.url} className='w-full h-full'></iframe>
            </Dialog>
            <Dialog header="Pridanie kalibrácie" visible={visibleCalibration} onHide={() => setVisibleCalibration(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
            <form onSubmit={formik.handleSubmit}>
            <div className="w-auto p-2 m-2 bg-white rounded-md">
            <div>   
                        <label
                            htmlFor="validator_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Kalibroval:
                        </label>
                        <select required
                        name="validator_id" onChange={formik.handleChange} value={formik.values.validator_id}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber možnosť</option>
                          {state.calibration_companies.map((calibration_company) => (
                            <option key={calibration_company.id} value={calibration_company.id}>{calibration_company.name}</option>
                            ))}
                            <option value="Interne">Interný zamestnanec</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="calibration_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum:
                        </label>
                        <input required
                            name='calibration_date'
                            type="date"
                            onChange={formik.handleChange} value={formik.values.calibration_date}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="calibration_number_protocol"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Číslo kal. protokolu:
                        </label>
                        <input
                            name='calibration_number_protocol'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.calibration_number_protocol}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="verification_result"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Výsledok overenia:
                        </label>
                        <select required
                        name='verification_result'
                        onChange={formik.handleChange} value={formik.values.verification_result}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="OK">OK</option>
                          <option value="NOK">NOK</option>
                        </select>
                        </div> 
                        <div>
                        <label
                            htmlFor="contractor_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Záznam vykonal:
                        </label>
                        <Select style={{ width: 220, height:42,fontSize:14, }} showSearch={true} optionFilterProp="children" value={formik.values.contractor_id || ""} onChange={(value) => formik.setFieldValue("contractor_id",value)} >
                        <Select.Option value="">Vyber zamestnanca</Select.Option>
                        {state.employees.map((employee) => 
                            <Select.Option key={employee.id} value={employee.id}>{employee.name}</Select.Option>
                            )}
                        </Select>
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Poznámka:
                        </label>
                        <input
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="gauge_category"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Protokol:
                        </label>
                        <input
                            accept='image/*,application/pdf'
                            name='files' 
                            ref={fileListRef}
                            onChange={handleFileChange}
                            className='block w-full px-4 py-2 mt-2 mb-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            type='file'
                            multiple="multiple"
                            id='file'/>
                        </div>           
            </div>
            <button className='bg-red-950 w-full p-2 text-white font-semibold rounded-md hover:bg-red-700' type='submit' ref={submitRef}>ULOŽIŤ</button>
            </form>
            </Dialog>
        </>
    )
}