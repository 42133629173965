import React,{ useContext, useEffect, useState,useRef } from "react";
import { useFormik } from 'formik';
import {authPostRequest} from './../../api/ApiController';
import AuthContext from '../../components/AuthContext';

export default function Login(){


    const [user,setUser] = useState(null);
    const [submit,setSubmit] = useState(false);
    const {login,verify}= useContext(AuthContext);

    const [showLogin, setShowLogin] = useState(true);
    const [unverifyUser, setUnverifyUser] = useState(null);
    const [showVerifyOTP, setShowVerifyOTP] = useState(false);

    const [OTP, setOTP] = useState('');

    const usernameInput = useRef(null);

    useEffect(() => {
        if (usernameInput.current) {
            usernameInput.current.focus();
        }
    }, []);

    /* navigate after logged in */
    //const navigate = useNavigate();

    /* check submit form */
    useEffect(() => {

        if(submit){
            setSubmit(false);
            if(user){
                console.log("USER",user);
                //navigate("/");
                verify(user);
            }
            else{
                alert("Nesprávne prihlasovacie údaje!");
            }
        }

    }, [user,verify,submit]);

    

    /* validate form */
    const validate = values => {
        const errors = {};
        if (!values.username) {
            errors.username = '*Povinné';
        }else if(!values.password){
            errors.password = '*Povinné';
        }
        return errors;
    };

    /* form for add customer */
    const formik = useFormik({
        initialValues: {
            username: '',password:''
        },
        validate,
        onSubmit: async (values) => {
            const logged_user = await login(values);
            if(logged_user.otp_enabled=="enabled"){
                setUnverifyUser(logged_user);
                setShowLogin(false);
                setShowVerifyOTP(true);
            }
            else{
                setUser(logged_user);
                setSubmit(true);
            }
        }
    });

    const onChangeHandler = event => {
        setOTP(event.target.value);
     };

    const handleSave = event => {
        event.preventDefault();
        //values=formik.values;
        //console.log("VALUES",values)
        var new_values = { one_time_password: OTP, username: formik.values.username};
        console.log("VALUES",new_values)
        authPostRequest('/api/auth/2fa', JSON.stringify(new_values))
            .then((response) => {
                console.log(response);
                if(response.status < 400){
                    //console.log("OTP VALID");
                    setUser(unverifyUser);
                    setSubmit(true);
                }
                else{
                    //console.log("OTP INVALID");
                    setUnverifyUser(null);
                    setShowLogin(true);
                    setShowVerifyOTP(false);
                }
            }).catch((reason) => {
                console.log(reason);
            })
    }

    return(
        <form onSubmit={formik.handleSubmit}>
            <div className='relative flex flex-col justify-center min-h-screen bg-red-950'>
                <div className='max-w-80 m-auto p-6 bg-white rounded-md shadow-md'>
                    <div className='flex justify-center items-center'>
                        <img src={require('../../assets/img/logo.png')} alt='Schelling Logo' className='h-16 w-16 px-2 py-2 object-cover'/>
                    </div>
                     <h1 className='text-center text-2xl font-bold mb-4'>Prihlásenie</h1>
                     <div>
                     <label className='font-semibold text-lg mb-2' htmlFor='username'>
                        Prihlasovacie meno
                        <input
                            name='username'
                            type='text'
                            className='w-full h-8 bg-white border rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            onChange={formik.handleChange} value={formik.values.username} ref={usernameInput}
                        />
                     </label>
                     {formik.errors.username ? <div style={{color:'red'}}>{formik.errors.username}</div> : null}
                     </div>
                     <div>
                     <label className='font-semibold text-lg mb-2' htmlFor='password'>
                        Heslo
                        <input
                            name='password'
                            type='password'
                            className='w-full h-8 mb-8 bg-white border rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            onChange={formik.handleChange} value={formik.values.password}
                        />
                     </label>
                     {formik.errors.password ? <div style={{color:'red'}}>{formik.errors.password}</div> : null}
                     </div>
                     <div>
                        <button type="submit" className='w-full h-8 bg-red-950 rounded-md font-bold text-white hover:bg-red-700'>Prihlásiť</button>
                     </div>
                </div>
            </div>
        </form>
    )
}