import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Navbar from '../../components/Navbar';
import HomePage from '../../HomePage';
import { SiGoogleforms } from "react-icons/si";
import { FaSearch } from "react-icons/fa";


export default function CalibrationHome() {

    return(
        <>
        <div className='flex flex-col px-2 pb-2 h-[calc(100vh_-_56px)] bg-gray-100'>
            <h1 className='text-3xl text-center text-red-900 font-bold mb-8'>Kalibrácia Domov</h1>
            <div className='flex items-center justify-left w-full h-32 border-2 border-red-900 rounded-md shadow-xl mb-8'>
                <Link to="/calibrationmeasure" className='flex flex-row items-center'>
                    <SiGoogleforms className='w-16 h-16 text-red-950'/>
                    <div className='flex flex-col'>
                    <h1 className='font-bold text-xl w-full flex items-center text-red-900'>NOVÉ MERADLO</h1>
                    <p className=''>Zadanie nového meradla</p>
                    </div>
                </Link>
            </div>
            <div className='flex items-center justify-left w-full h-32 border-2 border-red-900 rounded-md shadow-xl'>
                <Link to="/calibrationsearch" className='flex flex-row items-center'>
                    <FaSearch className='w-16 h-16 px-1 py-1 text-red-950'/>
                    <div className='flex flex-col'>
                    <h1 className='font-bold text-xl w-full flex items-center text-red-900'>PRIDAŤ KALIBRÁCIU</h1>
                    <p className=''>Pridať novú kalibráciu</p>
                    </div>
                </Link>
            </div>
            <div></div>
        </div>
        </>
    )
}