import React, {useContext, useState, useRef, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { CiBarcode } from "react-icons/ci";
import { SaveAndCloseIcon,SaveIcon, PrioBack,} from "../svg/svgIcons";
import { useFormik } from 'formik'; 
import UtilContext from '../../components/UtilContext';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import AuthContext from '../../components/AuthContext';

export default function GaugeAdd({gauge = null, type}){

    const navigate = useNavigate();

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,visible,setDialogText,setVisible}= useContext(UtilContext);

    const [trusteeDepartment, setTrusteeDepartment] = useState();
    const [trustees, setTrustees] = useState([]);
    const [userDepartment, setUserDepartment] = useState();
    const [users, setUsers] = useState([]);

    const [showGaugeAdd, setShowGaugeAdd] = useState(true);
    const [showIntervalCalibration, setShowIntervalCalibration] = useState(false);

    const [formGauge,setFormGauge] = useState(gauge);
    const [formType, setFormType] = useState(type);
    const [checkTimeCondition, setCheckTimeCondition] = useState(false);

    const [fileList, setFileList] = useState([]);
    const [saveAfterClose,setSaveAfterClose] = useState(false);

    const fileListRef = useRef(null);
    const submitRef = useRef(null);

    const [valueX,setValueX] = useState(gauge==null ? "X" : gauge.daily_deployment.toString());
    const [valueY,setValueY] = useState(gauge==null ? "Y" : gauge.abrasion.toString());
    const [valueU,setValueU] = useState(gauge==null ? "U" : gauge.environment_of_use.toString());
    const [valueV,setValueV] = useState(gauge==null ? "V" : gauge.sensitivity.toString());
    const [valueK,setValueK] = useState(gauge==null ? "K" : gauge.calibration_factor.toString());

    useEffect(() => {
        if(!isNaN(valueX) && !isNaN(valueY) && !isNaN(valueU) && !isNaN(valueV)){
            let result = valueX*valueY + valueU*valueV;
            setValueK(result);
        }
    },[valueX,valueY,valueU,valueV]);

    useEffect(() => {
        // Update the document title using the browser API
        if(formGauge!=null){
            if(formGauge.trustee_id){
                const trustee_id = state.employees.filter(employee => employee.id === formGauge.trustee_id);
                if(trustee_id.length > 0)filterTrustees(trustee_id[0].department.id);
            }
            if(formGauge.user_id){
                const user_id = state.employees.filter(employee => employee.id === formGauge.user_id);
                if(user_id.length > 0)filterUsers(user_id[0].department.id);
            }
        }
    },[formGauge]);

        const formik = useFormik({
            initialValues: {
                gauge_name: '',inventory_number: '',gauge_status:'',max_calibration_interval:'',serial_number:'',purchase_date:'',location:'',state:'',manufacturer:'',gauge_type:'',gauge_category:'',calibration_company_id:'',gauge_description:'',trustee_id:'',user_id:'',
            },
            enableReinitialize:true,
            onSubmit: values => {
                let formData = new FormData();
                for (let value in values) {
                    formData.append(value, values[value]);
                }
                formData.append("daily_deployment", valueX);
                formData.append("abrasion", valueY);
                formData.append("environment_of_use", valueU);
                formData.append("sensitivity", valueV);
                formData.append("calibration_factor", valueK);
                formData.append("contractor_id", user?.employee?.id ? user?.employee?.id : "" );
                    postRequest('/api/gauges', formData,true)
                    .then((response) => {
                        console.log(response);
                        formik.resetForm();
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                        if(saveAfterClose)navigate("/calibrationhome");
                        setVisible(false);
                    }).catch((reason) => {
                        alert("Daný úkon sa nepodaril!");
                        setVisible(false);
                    })
            },
         });

         
    function showForm(){
        if(valueK!=="K"){
            if(valueK == 2)formik.setFieldValue('max_calibration_interval',"60");
            else if(valueK >= 3 && valueK <= 4)formik.setFieldValue('max_calibration_interval',"48");
            else if(valueK >= 5 && valueK <= 7)formik.setFieldValue('max_calibration_interval',"24");
            else if(valueK >= 8 && valueK <= 10)formik.setFieldValue('max_calibration_interval',"12");
            else if(valueK >= 11 && valueK <= 15)formik.setFieldValue('max_calibration_interval',"6");
            else if(valueK >= 16 && valueK <= 18)formik.setFieldValue('max_calibration_interval',"3");
            setShowGaugeAdd(true);
            setShowIntervalCalibration(false);
        }
        else{
            alert('Vypočítaj rovnicu!');
        }
    }

    function showTable(){
        setShowGaugeAdd(false);
        setShowIntervalCalibration(true);
    }

    function filterTrustees(departmentId){
        setTrusteeDepartment(departmentId);
        setTrustees(state.employees
        .filter(employee => employee.department.id === parseInt(departmentId)));
    }

    function filterUsers(departmentId){
        setUserDepartment(departmentId);
        setUsers(state.employees
        .filter(employee => employee.department.id === parseInt(departmentId)));
    }

    return(
        <form className='w-full h-full bg-white' onSubmit={formik.handleSubmit}>
            { showGaugeAdd &&<div className='flex flex-col bg-white h-full pb-2'>
            <div className='sticky top-14 left-0 w-full h-10 flex flex-row justify-evenly border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => navigate("/calibrationhome")}><PrioBack/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => {setSaveAfterClose(true);submitRef.current.click()}}><SaveAndCloseIcon/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => submitRef.current.click()}><SaveIcon/></button></div>
        </div>
                <div className='w-auto p-4 m-2'>
                    <h1 className='w-full text-2xl font-bold mb-4 text-red-900'>NOVÉ MERADLO</h1>
                    <div className='flex flex-row m-autojustify-left mt-2'>
                </div>
                    <div className='w-full mb-2'>
                    <label htmlFor='gauge_name' className='font-semibold '>
                        Názov meradla:
                        <input 
                        className='w-full p-2 border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        type='text'
                        name='gauge_name'
                        onChange={formik.handleChange} value={formik.values.gauge_name}
                        />
                    </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label htmlFor='inventory_number' className='font-semibold '>
                        Inventárne číslo:
                        <input 
                        className='w-full p-2 border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        type='text'
                        name='inventory_number'
                        onChange={formik.handleChange} value={formik.values.inventory_number}
                        />
                    </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label htmlFor='gauge_status' className='font-semibold '>
                        Štatút meradla:
                        <select 
                        className='w-full p-2 border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        type='text'
                        name='gauge_status'
                        onChange={formik.handleChange} value={formik.values.gauge_status}
                        >
                            <option value="">Zvoľ štatút</option>
                            <option value="Kalibrované">Pracovné kalibrované meradlo</option>
                            <option value="Informatívne">Informatívne meradlo</option>
                        </select>
                    </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label htmlFor='max_calibration_interval' className='font-semibold '>
                        Max. kalibračný interval:
                        <button onClick={() => showTable()} type='submit' className='w-fit font-medium ml-2 mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-red-950 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-600'>Vyrátaj!</button>
                        <select 
                        className='w-full p-2 border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        type='text'
                        name='max_calibration_interval'
                        onChange={formik.handleChange} value={formik.values.max_calibration_interval}
                        >
                          <option value="0">0</option>
                          <option value="3">3 mesiace</option>
                          <option value="6">6 mesiacov</option>
                          <option value="12">12 mesiacov</option>
                          <option value="24">24 mesiacov</option>
                          <option value="48">48 mesiacov</option>
                          <option value="60">60 mesiacov</option>
                        </select>
                    </label>
                    </div>
                    <div>
                        <label
                            htmlFor="serial_number"
                            className='font-semibold'
                        >
                            Výrobné číslo:
                        <input 
                        name='serial_number'
                        type='text'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.serial_number}
                        />
                        </label>
                    </div> 
                    <div>
                        <label
                            htmlFor="purchase_date"
                            className='font-semibold'
                        >
                            Dátum nákupu:
                        <input 
                        name='purchase_date'
                        type='text'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.purchase_date}
                        />
                        </label>
                    </div> 
                    <div>
                        <label
                            htmlFor="location"
                            className='font-semibold'
                        >
                            Lokalita:
                        <select 
                        name='location'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.location}
                        >
                          <option value="">Zvoľ lokalitu</option>
                          <option value="Na pracovisku">Na pracovisku</option>
                          <option value="Na kalibrácií"> Na kalibrácií</option>
                          <option value="Na oprave">Na oprave</option>
                        </select>
                        </label>
                    </div> 
                    <div>
                        <label
                            htmlFor="state"
                            className='font-semibold'
                        >
                            Stav:
                            <select 
                        name='state'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.state}
                        >
                          <option value="">Zvoľ stav</option>
                          <option value="Aktívne">Aktívne</option>
                          <option value="Vyradené">Vyradené</option>
                        </select>
                        </label>
                    </div> 
                    <div>
                        <label
                            htmlFor="manufacturer"
                            className='font-semibold'
                        >
                            Výrobca meradla:
                        <input 
                        name='manufacturer'
                        type='text'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.manufacturer}
                        />
                        </label>
                    </div> 
                    <div className='w-full mb-2'>
                    <label
                            htmlFor="gauge_type"
                            className='font-semibold'
                        >
                            Typ meradla:
                            <select 
                        name='gauge_type'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.gauge_type}
                        >
                          <option value="">Zvoľ typ</option>
                          <option value="Digitálne">Digitálne</option>
                          <option value="Analógové"> Analógové</option>
                        </select>
                        </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label
                            htmlFor="gauge_category"
                            className='font-semibold'
                        >
                            Kategória meradla:
                            <select 
                        name='gauge_category'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.gauge_category}
                        >
                          <option value="">Zvoľ kategóriu</option>
                          <option value="Pracovné">PMU-Pracovné meradlo</option>
                          <option value="Skúšobné"> SM-Skúšobné meradlo</option>
                          <option value="Zálohové"> ZM-Zálohové meradlo</option>
                        </select>
                        </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label
                            htmlFor="calibration_company_id"
                            className='font-semibold'
                        >
                            Kalibračná firma:
                            <select 
                        name='calibration_company_id'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.calibration_company_id}
                        >
                          <option value="">Zvoľ kal. firmu</option>
                          {state.calibration_companies.map((calibration_company) => (
                            <option key={calibration_company.id} value={calibration_company.id}>{calibration_company.name}</option>
                            ))}
                        </select>
                        </label>
                    </div>
                    <div>
                        <label
                            htmlFor="gauge_description"
                            className='font-semibold'
                        >
                            Popis meradla:
                        <textarea 
                        name='gauge_description'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.gauge_description}
                        />
                        </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label
                            htmlFor="trustee_department"
                            className='font-semibold'
                        >
                            Oddelenie správcu:
                            <select 
                        name='trustee_department'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={(e) => filterTrustees(e.target.value)} value={trusteeDepartment}
                        >
                          <option value="">Zvoľ oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                        </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label
                            htmlFor="trustee_id"
                            className='font-semibold'
                        >
                            Meno správcu:
                            <select 
                        name='trustee_id'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.trustee_id}
                        >
                          <option value="">Zvoľ meno</option>
                          {trustees.map((trustee) => (
                            <option key={trustee.id} value={trustee.id}>{trustee.name}</option>
                            ))}
                        </select>
                        </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label
                            htmlFor="user_department"
                            className='font-semibold'
                        >
                            Oddelenie užívateľa:
                            <select 
                        name='user_department'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={(e) => filterUsers(e.target.value)} value={userDepartment}
                        >
                          <option value="">Zvoľ oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                        </label>
                    </div>
                    <div className='w-full mb-2'>
                    <label
                            htmlFor="user_id"
                            className='font-semibold'
                        >
                            Meno užívateľa:
                            <select 
                        name='user_id'
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border border-black rounded-md focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        onChange={formik.handleChange} value={formik.values.user_id}
                        >
                          <option value="">Zvoľ meno</option>
                          {users.map((user) => (
                            <option key={user.id} value={user.id}>{user.name}</option>
                            ))}
                        </select>
                        </label>
                    </div>
                </div>
            </div>}
            { showIntervalCalibration && <div className='flex flex-col justify-center'>
                <div className='w-auto p-2 m-4 bg-white rounded-md'>
                <h1 className='text-2xl font-bold text-red-900 text-center px-1 py-1 m-auto mb-2'>
                    Odporúčaný výpočet kalibračného intervalu
                </h1>
                <h1 className='text-3xl font-semibold text-zinc-900 text-center px-1 py-1 m-auto mb-2'>
                    K = {valueX} * {valueY} + {valueU} * {valueV} {valueK!=="K" && ` = ${valueK}`}
                </h1>
                <div className='flex flex-col w-full justify-center items-center'>
                    <div className='w-full border border-black px-2 py-2 rounded-sm mb-2'>
                        <div><h1 className='text-lg font-semibold text-center'>Denné nasadenie X</h1>
                        <div>
                            <div className='flex justify-between'>
                                1-krát
                                <div>
                                <input type="radio" value="1" 
                                            checked={valueX === "1"} 
                                            onChange={(e) => setValueX(e.target.value)} />
                                1
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                1 až 20 krát
                                <div>
                                <input type="radio" value="2" 
                                            checked={valueX === "2"} 
                                            onChange={(e) => setValueX(e.target.value)} />
                                2
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                Viac ako 20 krát
                                <div>
                                <input type="radio" value="3" 
                                            checked={valueX === "3"} 
                                            onChange={(e) => setValueX(e.target.value)} />
                                3
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className='w-full border border-black px-2 py-2 rounded-sm mb-2'>
                        <div><h1 className='text-lg font-semibold text-center'>Opotrebovanie Y</h1>
                        <div>
                            <div className='flex justify-between'>
                                Minimálne
                                <div>
                                <input type="radio" value="1" 
                                            checked={valueY === "1"} 
                                            onChange={(e) => setValueY(e.target.value)} />
                                1
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                Normálne
                                <div>
                                <input type="radio" value="2" 
                                            checked={valueY === "2"} 
                                            onChange={(e) => setValueY(e.target.value)} />
                                2
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                Vysoké
                                <div>
                                <input type="radio" value="3" 
                                            checked={valueY === "3"} 
                                            onChange={(e) => setValueY(e.target.value)} />
                                3
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className='w-full border border-black px-2 py-2 rounded-sm mb-2'>
                        <div><h1 className='text-lg font-semibold text-center'>Prostredie využitia U</h1>
                        <div>
                            <div className='flex justify-between'>
                                Vnútorné použitie
                                <div>
                                <input type="radio" value="1" 
                                            checked={valueU === "1"} 
                                            onChange={(e) => setValueU(e.target.value)} />
                                1
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                Výroba
                                <div>
                                <input type="radio" value="2" 
                                            checked={valueU === "2"} 
                                            onChange={(e) => setValueU(e.target.value)} />
                                2
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                Vonkajšie použitie
                                <div>
                                <input type="radio" value="3" 
                                            checked={valueU === "3"} 
                                            onChange={(e) => setValueU(e.target.value)} />
                                3
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className='w-full border border-black px-2 py-2 rounded-sm mb-2'>
                        <div><h1 className='text-lg font-semibold text-center'>Citlivosť V</h1>
                        <div>
                            <div className='flex justify-between'>
                                Robustná
                                <div>
                                <input type="radio" value="1" 
                                            checked={valueV === "1"} 
                                            onChange={(e) => setValueV(e.target.value)} />
                                1
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                Normálna
                                <div>
                                <input type="radio" value="2" 
                                            checked={valueV === "2"} 
                                            onChange={(e) => setValueV(e.target.value)} />
                                2
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                Vysoká
                                <div>
                                <input type="radio" value="3" 
                                            checked={valueV === "3"} 
                                            onChange={(e) => setValueV(e.target.value)} />
                                3
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                </div>
                <div className='flex flex-col w-full justify-center items-center'>
                 <table className='w-full h-4 text-center'>
                    <tbody className=' border border-black rounded-md'>
                        <tr className='border border-black px-1 bg-white font-semibold'>
                            <td className=' border-black'>Kalibračný faktor K</td>
                            <td className=' border-black'>Interval kalibrácie (mesiace)</td>
                            <td className=' border-black'>Kalibračný faktor K</td>
                            <td className=' border-black'>Interval kalibrácie (mesiace)</td>
                        </tr>
                        <tr className='border border-black bg-gray-200'>
                            <td className=' border-black'>2</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="60" 
                                                checked={valueK == 2} readOnly />
                                    60
                                </label>
                            </td>
                            <td className=' border-black'>8-10</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="12" 
                                                checked={valueK >= 8 && valueK <=10} readOnly />
                                    12
                                </label>
                            </td>
                        </tr>
                        <tr className='border border-black bg-white'>
                            <td className=' border-black'>3-4</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="48" 
                                                checked={valueK >= 3 && valueK <=4} readOnly />
                                    48
                                </label>
                            </td>
                            <td className=' border-black'>11-15</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="6" 
                                                checked={valueK >= 11 && valueK <=15} readOnly />
                                    6
                                </label>
                            </td>
                        </tr>
                        <tr className='border border-black bg-gray-200'>
                            <td className=' border-black'>5-7</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="24" 
                                                checked={valueK >= 5 && valueK <=7} readOnly />
                                    24
                                </label>
                            </td>
                            <td className=' border-black'>16-18</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="3" 
                                                checked={valueK >= 16 && valueK <=18} readOnly />
                                    3
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button onClick={() => showForm()} className='w-fit font-medium mt-2 mb-8 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-red-950 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>Uložiť!</button>
                 </div>
                </div>
            </div> }
            <button type="submit" ref={submitRef} className='hidden'></button>
        </form>
    )
}